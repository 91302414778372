@import './font.css';
@import './clash-grotesk.css';
@import './vars.css';

/****** 
// UNCOMMENT BELOW FOR NO DEBUG POPUP
*******/
nextjs-portal {
  /* display: none; */
}
body {
  /* overflow: unset !important; */
}
/******
*******/

:root {
  color-scheme: dark;
}

.mobile-only {
  display: none;
}

* {
  margin: 0;
  box-sizing: border-box;
}
*:focus {
  outline-color: var(--clr-brand);
  outline-width: 5px;
}
html {
  font-size: 1em;
}
body,
html {
  height: 100%;
  color: var(--clr-text);
  background: var(--clr-bg);
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Lucida Console',
    monospace;
  -webkit-font-smoothing: antialiased;
  scrollbar-gutter: stable both-edges;
}

input,
select,
button {
  font-family: inherit;
  font-size: inherit;
}
input[type='checkbox'],
input[type='radio'] {
  width: 1rem;
  height: 1rem;
}
button {
  cursor: pointer;
}
label,
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}
input[disabled],
input[readonly],
button[disabled],
label:has(input[disabled]) {
  cursor: not-allowed;
}
code {
  font-family: inherit;
  font-size: inherit;
  background: var(--clr-box-bg);
  padding: 0.1rem 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid var(--clr-border);
}
body {
  /* min-width: 1280px; */
}
hr {
  border-color: var(--clr-border);
  margin: 1rem 2rem;
}
ul {
  margin: 0;
  padding: 0;
}
.para {
  margin: 1em 0;
  letter-spacing: 0.2px;
}
.link {
  color: var(--clr-link);
}

.link--no-underline {
  color: var(--clr-link);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
.heading {
  margin: 0;
  color: var(--clr-heading);
}
.small {
  font-size: var(--font-size-0);
}
.ordered-list,
.unordered-list {
  padding-left: 2em;
  letter-spacing: 0.2px;
}
.ordered-list li:not(:last-child),
.unordered-list li:not(:last-child) {
  line-height: 1.5;
  margin-bottom: 1rem;
}
.image {
  max-width: 100%;
}
figure {
  margin: 1rem 0;
}
figure > caption,
figure > figcaption {
  display: block;
  text-align: center;
  color: var(--clr-text-light);
}
figure img {
  border-radius: 0.5rem;
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.table {
  border-collapse: collapse;
}
.table td,
.table th {
  padding: 0.6rem 1.5rem;
  font-variant-numeric: lining-nums tabular-nums;
}
.table thead tr {
  background: rgba(0, 0, 0, 0.2);
}

.table tr td {
  color: var(--clr-text-lightest);
}

.table thead th {
  font-weight: 600;
  font-family: ClashGrotesk;
}

.table tr:nth-child(2n) {
  background: rgba(0, 0, 0, 0.2);
}
.d-if {
  display: inline-flex;
}
.relative {
  position: relative;
}
.new-font {
  font-family: 'ClashGrotesk';
  letter-spacing: 0.3px;
  word-spacing: 3px;
}
.ta-c {
  text-align: center;
}
.d-f {
  display: flex;
}
.jc-sb {
  justify-content: space-between;
}
.ai-c {
  align-items: center;
}
.fxg-1 {
  flex-grow: 1;
}
.width--full {
  width: 100%;
}
.btn-group {
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, auto));
  justify-content: flex-end;
  gap: 0.5rem;
  align-items: center;
}
.btn-group--center {
  justify-content: center;
}
.btn-group--nopadding {
  padding: 0;
}

.btn-group--with-background {
  background: var(--clr-box-bg);
  border-top: 1px solid var(--clr-border);
}

.btn-group--with-background .btn-group {
  justify-content: stretch;
  grid-template-columns: repeat(auto-fill, minmax(220px, auto));
}

.header {
  background: var(--clr-header-bg);
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left,
.header__right,
.user-info,
.target-leader {
  display: flex;
  align-items: center;
}
.header__left {
  gap: 1.5rem;
}

.loader {
  fill: currentColor;
}
.footer {
  background: var(--clr-footer-bg);
  padding: 0.7rem 1.25rem;
  color: var(--clr-text-light);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.footer__links {
  flex-grow: 1;
  /* overflow: hidden; */
}

.footer__copyright {
  font-size: var(--font-size-1);
  font-family: 'ClashGrotesk';
  font-weight: 500;
  color: var(--clr-text-lightest-final);
}

.footer__links a {
  /* font-family: ClashGrotesk; */
  color: var(--clr-link);
  text-decoration: none;
  font-weight: 500;
}

.footer__links a:hover,
.footer__links a:focus {
  color: var(--clr-link);
  text-decoration: underline;
}

.footer__column-title {
  font-family: 'ClashGrotesk';
  font-size: var(--font-size-0);
  font-weight: 600;
  /* color: var(--clr-brand); */
  color: var(--clr-text-lightest-final);
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.footer__deco {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  filter: grayscale(1);
  opacity: 0.08;
}

.footer__deco:after {
  content: '';
  position: absolute;
  inset: 0;
  bottom: auto;
  height: 4rem;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(var(--rgb-gray-0), 1), #0000);
}
.footer__deco img {
  min-width: 0;
}
@media (max-width: 768px) {
  .v2-footer {
    padding: 1.5rem 1.25rem;
  }

  .footer__links > div {
    flex-direction: column;
    gap: 2rem;
  }
}

.homepage-banner {
  background: var(--clr-banner-bg);
  padding: 2rem;
  color: var(--clr-text);
  text-align: center;
}

.homepage-banner__heading {
  font-size: var(--font-size-4);
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.homepage-banner__description {
  font-size: var(--font-size-2);
  max-width: 75%;
  line-height: 1.5;
  margin: 0 auto 0;
}

.battle-banner__description {
  max-width: 100%;
}

.homepage-banner--new-battle {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

#battle-particles {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: -1;
}

.countdown__number {
  font-weight: 700;
  font-size: var(--font-size-3);
  margin-right: 0.4rem;
}

.countdown__number--small {
  font-weight: 500;
  font-size: unset;
  margin-right: 0;
}

.countdown--big .countdown__number {
  font-weight: 600;
  font-size: 3rem;
  color: var(--clr-header-text);
  /* margin-right: 0; */
}
.countdown--big .countdown__separator {
  font-size: 3rem;
}
.countdown__separator {
  opacity: 0.4;
}

.logo a {
  display: block;
  width: 137px;
  height: 32px;
  background: url(/images/logo.svg) no-repeat;
}
.logo:hover {
  filter: drop-shadow(0px 0px 25px yellow);
}

.logo-image {
  display: inline-block;
  background-size: contain;
  background-position: center;
  height: 18px;
  background-repeat: no-repeat;
}

.logo-image--full {
  width: 140px;
  background-image: var(--logo-url);
}

.logo-image--glyph {
  width: 32px;
  background-image: var(--logo-small-url);
}

.level {
  font-size: var(--font-size-1);
  color: var(--clr-heading);
  display: flex;
  align-items: center;
}
.user-info,
.target-leader {
  font-weight: 700;
  color: var(--clr-text);
  text-decoration: none;
  margin-left: 20px;
}

.user-info {
  margin: 0;
}

.header__right > * {
  margin-left: 1rem;
}

.user-info__avatar {
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;
  flex-shrink: 0;
  /* margin-right: 0.75rem; */
}

.button,
.dropdown-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1rem;
  border: 0;
  border-radius: 40px;
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
  background-color: var(--clr-btn);
  color: var(--clr-btn-secondary-text);
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s ease;
  line-height: 1.2;
  transition-property: transform, background-color;
  font-family: ClashGrotesk;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: left;
}

.dropdown-btn--user {
  padding: 0.35rem 0.55rem;
}

.button .pro-badge,
.settings-tabs .pro-badge {
  margin-left: 0.5rem;
}

.button[disabled],
select[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

.button:hover,
.button:focus-visible,
.dropdown-btn:hover,
.dropdown-btn:focus-visible {
  /* dummy transform otherwise in a "cool" button the animating pseudo element shows behind the background! */
  transform: scale(1);

  background-color: var(--clr-btn-hover);
}
.button[data-transition='false']:hover {
  transition: none;
  transform: none;
}
.button:active,
.dropdown-btn:active {
  transform: scale(0.9);
}

.button--danger {
  background: var(--clr-btn-danger);
}

.button--danger:hover,
.button--danger:focus {
  background: var(--clr-btn-danger-hover);
}

.button--icon {
  padding: 0.6rem;
}

.button > svg {
  fill: currentColor;
}

.button > svg:first-child {
  margin-right: 0.5em;
}

.button--full-width {
  width: 100%;
  text-align: center;
}

.button--cssbattle {
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgb(254 227 51);
  background: rgb(254 227 51 / 15%);
  padding: 0.6rem 1.5rem;
  transition: 0.3s ease;
}
.button--cssbattle:before {
  content: '';
  z-index: -1;
  display: block;
  position: absolute;
  inset: 0;
  clip-path: xywh(0 0 0% 100% round 0 1px 2% 3px);
  background: var(--clr-brand);
  transition: 0.3s ease;
  border-radius: 999px;
}
.button--cssbattle:hover:before {
  clip-path: xywh(0 0 100% 100% round 0 1px 2% 3px);
}
.button--cssbattle:hover {
  color: black;
}

.button--lg {
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
}
.button--xl {
  font-size: 2rem;
  padding: 1.5rem 2.3rem;
}
.button--glow {
  position: relative;
  background: rgb(255 255 255 / 10%);
}
.button--glow:hover {
  background: rgb(255 255 255 / 0%);
}

.button--animated-icon {
  position: relative;
}
.button--animated-icon .icon {
  transform: translate(0, 0);
  transition: 250ms ease;
  display: inline-block;
}
.button--animated-icon:after {
  content: '🚀';
  position: absolute;
  font-size: 4rem;
  z-index: -1;
  bottom: 51%;
  left: 40%;
  transform: translateY(20px);
  opacity: 0;
  transition: 250ms ease;
}
.button--animated-game:after {
  content: '🕹️';
}
.button--animated-learn:after {
  /* content: '🎓'; */
}
.button--animated-icon:hover .icon {
  transform: translate(0px, -10px);
  opacity: 0;
  margin-left: -1.2ch;
}
.button--animated-icon:hover:after {
  opacity: 1;
  transform: translateY(0px);
}

.button__border {
  /* yellow highlight */
  --main-color-rgb: 255, 223, 0;

  position: absolute;
  inset: 0;
  border-radius: 99999px;
  pointer-events: none;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  border: 1px solid rgba(var(--main-color-rgb), 0.2);

  background: conic-gradient(
      from calc(var(--border-rotation) - 80deg) at var(--border-x) 22px,
      rgba(var(--main-color-rgb), 0) 0%,
      rgba(var(--main-color-rgb), 0.8) 30%,
      rgba(177, 177, 177, 0) 45%
    )
    border-box;

  animation: -0.64s border-rotation 6s linear infinite,
    -0.64s border-x 6s linear infinite;
}

@property --border-x {
  syntax: '<length>';
  inherits: false;
  initial-value: 0px;
}

@property --border-rotation {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

:root {
  --btn-size: 158px;
  --offset: calc(var(--btn-size) / 10);
}

@keyframes border-x {
  0% {
    --border-x: var(--offset);
  }
  32.82275711% {
    --border-x: var(--btn-size);
  }
  50% {
    --border-x: var(--btn-size);
  }
  82.82275711% {
    --border-x: var(--offset);
  }
  100% {
    --border-x: var(--offset);
  }
}

@keyframes border-rotation {
  0% {
    --border-rotation: 0deg;
  }
  32.82275711% {
    --border-rotation: 0deg;
  }
  50% {
    --border-rotation: 180deg;
  }
  82.82275711% {
    --border-rotation: 180deg;
  }
  100% {
    --border-rotation: 360deg;
  }
}

.header__right .button {
  margin-left: 1rem;
}

.dropdown-btn::-webkit-details-marker {
  display: none;
}

.dropdown-btn {
  display: inline-flex;
  align-items: center;
  padding-right: 1.7rem; /* 1.7 = 0.9 (btn padding) + 0.8 (icon size) */
  background-image: url(/images/chevron-bottom.svg);
  background-size: 0.8rem;
  background-position: center right 0.5rem;
  background-repeat: no-repeat;
  font-family: 'ClashGrotesk';
  font-weight: 600;
}

.dropdown-btn--dir-right {
  background-image: url(/images/chevron-right.svg);
}

.button--small,
.dropdown-btn--small {
  font-size: var(--font-size-0);
  padding: 0.25rem 0.5rem 0.2rem;
  background-color: var(--clr-dropdown-btn);
}

.button--mini,
.dropdown-btn--mini {
  font-size: var(--font-size-0);
  padding: 0.1rem 0.3rem 0.1rem;
  background-color: var(--clr-dropdown-btn);
}

.button--medium {
  font-size: var(--font-size-0);
  padding: 0.4rem 0.75rem 0.35rem;
}

.button--big {
  font-size: var(--font-size-2);
  padding: 0.7rem 1.4rem 0.7rem;
}

.button--huge {
  font-size: var(--font-size-4);
  padding: 1rem 2rem 1rem;
}

.dropdown-btn--small {
  padding-right: 1.25rem;
  background-size: 0.6rem;
  background-position: top 0.4rem right 0.35rem;
}

.dropdown-btn--mini {
  padding-right: 1rem;
  background-size: 0.6rem;
  background-position: top 0.3rem right 0.3rem;
}

.dropdown-btn--minimal {
  padding: 0 1rem 0 0;
  background-color: transparent;
  box-shadow: none;
  font-weight: 500;
  line-height: 1.5;
  background-size: 0.7rem;
  background-position: center right 0;
}

.dropdown-btn--no-padding {
  padding: 0;
}

.dropdown-container--onboarding {
  width: 100%;
}

.dropdown-container--onboarding .dropdown-btn {
  width: 100%;
  background-position: right 0.75rem center;
}

.dropdown-btn--minimal:hover {
  transform: none;
  background-color: transparent;
}

.dropdown-btn--minimal {
  color: var(--clr-text);
}

.dropdown-btn--primary,
.button--primary {
  background-color: var(--clr-btn-primary);
  color: var(--clr-btn-text);
}

.button--primary:hover,
.button--primary:focus {
  background: var(--clr-btn-primary-hover);
}

.dropdown-btn--success,
.button--success {
  background-color: var(--clr-btn-success);
  color: var(--clr-btn-text);
}

.button--success:hover,
.button--success:focus {
  background: var(--clr-btn-success-hover);
}

.dropdown-btn--leaderboard-switch {
  margin-left: 0.5rem;
}

.dropdown-btn--no-padding {
  padding-block-start: 0;
  padding-block-end: 0;
}

.dropdown-btn--tertiary {
  background-color: transparent;
}

.dropdown-container {
  position: relative;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.dropdown-container--full-width {
  width: 100%;
}

.dropdown-menu {
  text-transform: initial;
  --offset-y: 5px;
  position: absolute;
  top: calc(100% + var(--offset-y));
  left: 0;

  /* Because 10 is on header */
  z-index: 11;

  border-radius: 0.5rem;
  backdrop-filter: blur(20px);
  background: var(--panel-bg);
  box-shadow: var(--panel-shadow);
  padding: 0.5rem 1.25rem;
  box-shadow: 0 10px 80px rgba(0, 0, 0, 0.75);
  animation: fadeIn 0.25s ease-in-out;
  font-family: 'ClashGrotesk';
  font-weight: 500;
  letter-spacing: 0.02rem;
}

.dropdown-menu--no-padding {
  padding: 0;
  overflow: hidden;
}

.dropdown-menu--small {
  width: 400px;
  max-width: 100vw;
}

.dropdown-menu--scrollable {
  max-height: 35vh;
  overflow-y: auto;
}

.dropdown-menu--grow-with-content {
  min-width: max-content;
  max-width: 40ch;
}

.dropdown-menu--fixed {
  position: fixed;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu__item {
  display: block;
  white-space: nowrap;
  color: var(--clr-link);
  text-decoration: none;
  padding: 0.5rem 3rem 0.5rem 0;
  background: none;
  font-weight: 500;
  border: 0;
  cursor: pointer;
}

.dropdown-menu__item:hover {
  text-decoration: underline;
}

.dropdown-menu__item--sun,
.dropdown-menu__item--moon {
  background: url(/images/icons/sun.svg) no-repeat;
  background-position: center right;
  background-size: 16px;
}

.dropdown-menu__item--moon {
  background-image: url(/images/icons/moon.svg);
  background-size: 12px;
}

.dropdown-menu--right {
  left: auto;
  right: 0;
}

.dropdown-menu--top {
  bottom: calc(100% + var(--offset-y));
  top: auto;
}

.dropdown-menu--text {
  width: 600px;
  max-width: 100vw;
  padding: 2rem;
  line-height: 1.4;
}

.dropdown-menu--text a {
  color: var(--clr-link);
  font: inherit;
}

.dropdown-menu--text li {
  margin-top: 0.75rem;
}

.dropdown-container[open] .dropdown-btn {
  transform: scale(1);
}

.dropdown-selector {
  background: none;
  border: 0;
  text-align: left;
  padding: 1rem;
  width: 100%;
  transition: background 0.2s ease;
  font-family: inherit;
  font-weight: inherit;
}
.dropdown-selector:not(:last-child) {
  border-bottom: 1px solid var(--clr-border);
}
.dropdown-selector__tag {
  color: var(--clr-brand);
  font-size: 0.75rem;
  text-transform: uppercase;
  background: rgba(var(--rgb-brand), 0.15);
  border-radius: 0.25rem;
  padding: 0.1rem 0.4rem;
  /* box-shadow: inset 1px 1px 2px rgba(var(--rgb-brand), 0.2); */
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.dropdown-selector:not(:disabled):hover,
.dropdown-selector:not(:disabled):focus {
  background: var(--clr-tab-bg-selected);
  color: white;
}

.small-pill {
  display: inline-block;
  font-size: var(--font-size-0);
  font-weight: 600;
  color: var(--clr-btn-text);
  padding: 0.1rem 0.4rem 0;
  background: var(--clr-dropdown-btn);
  border-radius: 50px;
  text-transform: uppercase;
  line-height: 1.4;
  font-family: 'ClashGrotesk';
}

.small-pill--primary {
  background: var(--clr-btn-primary);
}

.container {
  display: flex;
  min-height: calc(100vh - 6.76rem);
}

.container--fixed-height {
  height: calc((100vh - 60px) - 40px);
}

.container--hide-footer {
  min-height: calc(100vh - 4rem);
}

.container--centered-box {
  background: var(--clr-footer-bg);
  justify-content: center;
  align-items: center;
}

.container--pro-page {
  background: var(--clr-footer-bg);
}

.container--pro-page .container__item {
  width: 1280px;
  margin: 2rem auto;
  border: 0;
}

.container--centered-box .container__item {
  border: 0;
  background: var(--clr-bg);
  border-radius: 10px;
  padding: 1.25rem;
  min-height: auto;
  max-width: max-content;
  margin: 0 1rem;
}

.container--centered-box .heading {
  margin: -2.5rem;
  margin-bottom: 2rem;
  padding: 1.5rem 2.5rem;
  background: var(--clr-box-bg);
}

.login-form-container hr {
  outline: none;
  border: none;
  height: 1px;
  background: var(--clr-border-brighter);
  margin: 0 0 2rem 0;
}

.login-buttons-container {
  margin: -0.5rem;
}

.login-form-container .para {
  line-height: 1.6;
  color: var(--clr-text);
}

.login-disclaimer {
  text-align: left;
  margin: 0 4rem;
  padding: 1rem 2rem;
  background: rgba(var(--rgb-tile-bottom), 0.4);
  color: var(--clr-text-lightest-final);
  font-size: var(--font-size-0);
  font-family: ClashGrotesk;
  font-weight: 500;
  letter-spacing: 0.2px;
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  z-index: -1;
}

.pro-feature {
  list-style: none;
  font-family: 'ClashGrotesk';
  padding: 2rem;
  border: 2px solid var(--clr-border-1);
  border-radius: 1rem;
}

.pro-feature__title {
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: var(--font-size-2);
  color: var(--clr-brand);
}
.pro-feature__desc {
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: var(--clr-text-lightest);
}

.pro-feature__coming-soon {
  display: inline-block;
  color: #c2953b;
  padding: 1px 10px;
  border-radius: 4px;
  border: 1px solid #c2953b;
}
.pro-feature__number {
  color: var(--clr-brand);
  font-size: 3rem;
  font-weight: 700;
  width: 5rem;
  line-height: 5rem;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  text-align: center;
  background: var(--clr-border-1);
  border-radius: 50%;
}

.pro-left,
.pro-right {
  display: inline-block;
}

.pro-right {
  width: 55%;
  margin-left: 5rem;
}
.pro-left {
  width: 35%;
  vertical-align: top;
  position: sticky;
  top: 50px;
}

.para--small {
  font-size: var(--font-size-0);
  color: var(--clr-text-lightest);
}

.benefits-desc {
  padding: 1rem 2rem;
  border-radius: 10px;
  background: var(--clr-bg);
  line-height: 1.6;
}

.pro-pricing {
  font-family: 'ClashGrotesk';
  font-size: var(--font-size-2);
  font-weight: 500;
  text-align: center;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-text-lightest-final);
}

.pro-pricing__price {
  font-size: var(--font-size-4);
  color: var(--clr-text);
}

.pro-pricing__small {
  font-size: var(--font-size-0);
  color: var(--clr-text-lightest-final);
  background: black;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}

.pro-pricing-panel {
  width: 100%;
  position: relative;
  z-index: 0;
}

.pro-pricing-panel:before,
.pro-pricing-panel:after {
  content: '';
  position: absolute;
  width: 250px;
  left: -250px;
  height: 169px;
  top: 50%;
  /* transform: translateY(-50%); */
  background: url(/images/pro-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  border-radius: 16px 0 0 16px;
  z-index: -1;
  mask-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0.15));
  animation: pricing-bg-move linear;
  animation-timeline: scroll(root block);
}

.pro-pricing-panel:after {
  background-position: right center;
  border-radius: 0 16px 16px 0;
  left: unset;
  right: -250px;
  z-index: -1;
  mask-image: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0.15));
}

@keyframes pricing-bg-move {
  from {
    transform: translateY(-40%);
  }
  to {
    transform: translateY(-180%);
  }
}

.pricing-box {
  margin: 1rem 0;
  text-align: center;
}

.pricing-box__price {
  font-size: var(--font-size-4);
  font-weight: bold;
}

.pro-features {
  display: grid;
  grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
  gap: 2rem;
  margin-top: 1rem;
}

.pricing-limited-time-banner {
  --tx: -50%;
  font-family: 'ClashGrotesk';
  background: var(--clr-brand);
  bottom: 100%;
  padding: 0.3rem 3rem;
  color: #333;
  left: 50%;
  position: absolute;
  letter-spacing: 0.3px;
  font-weight: 500;
  width: max-content;
  border-radius: 4px 4px 0 0;
  opacity: 0;
  transform: translate(var(--tx), 102%);
  transition: 0.3s ease;
  transition-delay: 0;
}
.pricing-limited-time-banner[data-show='true'] {
  opacity: 1;
  transform: translate(var(--tx), 0);
  transition-delay: 0.8s;
}

.highlighted-link,
.highlighted {
  color: var(--clr-brand);
}

.login-disclaimer a {
  color: var(--clr-text);
  text-decoration: none;
  line-height: 1.6;
}

.login-disclaimer a:hover {
  text-decoration: underline;
}

.container__item {
  /* So that flex children don't overflow out of parent */
  min-width: 27.5rem;
  max-width: 100%;
  min-height: calc(100vh - 8rem);
  flex-shrink: 0;
  border-right: 1px solid var(--clr-border);
  position: relative;
}

.container__item--main {
  flex: 1;
}
.container__item--aside {
  flex: 0 0 25rem;
  border-right: 0;
}

.settings-container {
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
}

.container__item--target {
  max-width: 25vw;
}

.container__item--sticky,
.container__item--user-info,
.container__item--choose-leaderboard {
  flex: 0 0 25rem;
  border-right: 0;
  align-self: flex-start;
  position: sticky;
  top: 0;
  max-height: 100%;
  overflow-y: auto;
}

/* .container__item--leaderboard .item__header,
.container__item--user-info .item__header,
.container__item--choose-leaderboard .item__header {
  position: sticky;
  top: 0;
  z-index: 2;
} */

.container__item--user-info,
.container__item--choose-leaderboard {
  border-right: 1px solid var(--clr-border);
}

.profile__user-details {
  padding: 2.5rem;
  text-align: center;
}

.container__item--user-info .item__header,
.container__item--choose-leaderboard .item__header {
  background: var(--clr-inner-header-highlight);
}

.user-details__avatar {
  border-radius: 50%;
}

.user-details__name {
  font-family: 'ClashGrotesk';
  font-weight: 600;
  color: var(--clr-link);
  font-size: var(--font-size-3);
  white-space: nowrap;
}

.user-details__username {
  font-family: 'ClashGrotesk';
  font-weight: 500;
  color: var(--clr-text-lightest);
  font-size: var(--font-size-1);
  border: 2px solid var(--clr-border);
  border-radius: 999px;
  padding: 0.25rem 0.5rem;
  background: transparent;
}

.link--user-social {
  color: var(--clr-link);
}

.link--user-social svg {
  transition-property: opacity, color, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  opacity: 0.75;
}

.link--user-social svg:hover {
  color: var(--clr-brand);
  opacity: 1;
  transform: translateY(-2px);
}

.container__item--target {
  border-right: none;
}

.container__item--editor {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  background: var(--clr-editor-bg);
}

.container__item--target,
.container__item--output {
  background: var(--clr-box-bg-darker);
  width: 440px; /* target is 400px + 20px padding on either side */
}

.levelpage__target {
  aspect-ratio: 4 / 3;
}
.item__header,
.inner-header {
  display: flex;
  align-items: center;
  /* height: 40px; */
  /* line-height: 40px; */
  padding: 0.4rem 1.25rem;
  border-top: 1px solid var(--clr-border-brighter);
  font-family: ClashGrotesk;
}

.item__header {
  background: var(--clr-inner-header);
}

.container__item--main .item__header,
.item__header--css,
.item__header--editor,
.item__header--targets,
.container__item--targets .item__header,
.container__item--battles .item__header {
  background: var(--clr-inner-header-highlight);
}

.item__header--sticky {
  position: sticky;
  z-index: 2;
  top: 0;
}

.header__title {
  color: var(--clr-text);
  font-weight: 600;
  font-size: var(--font-size-1);
}

.key-combo {
  font-size: var(--font-size-0);
  color: var(--clr-text);
  margin-left: 10px;
  background: var(--clr-bg);
  padding: 0 0.5rem;
  border-radius: 40px;
  font-weight: 600;
  text-transform: uppercase;
}

.key-combo--inner-header {
  background: var(--clr-border);
}

.header__extra-info {
  flex-grow: 1;
  text-align: right;
  font-weight: 500;
  /* font-size: 16px; */
  color: var(--clr-text-light);
  text-transform: none;
}

.item__content {
  padding: 1.25rem;
}
.item__content--no-padding {
  padding: 0;
}

.item__content--inner-page {
  padding: 2rem;
  line-height: 2;
  color: var(--clr-text);
}

.item__content--inner-page a:not(.button) {
  color: var(--clr-link);
}

.item__content--centered {
  margin-left: auto;
  margin-right: auto;
}

.inner-header {
  border-top: none;
  padding: 0;
  margin-top: 2rem;
  /* height: 20px; */
  position: relative;
  z-index: 1;
}
.inner-header > * {
  position: relative;
}

.inner-header > *:before {
  content: '';
  position: absolute;
  left: 0;
  right: -20px;
  top: 0;
  bottom: 0;
  background: var(--clr-box-bg-darker);
  z-index: -1;
}
.inner-header:after {
  height: 1px;
  width: 100%;
  background: var(--clr-inner-header);
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  z-index: -2;
}

.inner-header--inner-page {
  margin: 2.5rem 0 1.25rem;
}

.island,
.hints-box,
.score-container {
  background: var(--clr-bg);
  border-radius: 16px;
  padding: 1.25rem;
  margin-top: 1rem;
  color: var(--clr-text-light);
  line-height: 1.4;
  font-family: ClashGrotesk;
  font-weight: 500;
}

.score-container {
  margin-top: 1rem;
}

.island {
  display: flex;
}

.sponsor-image {
  margin-right: 1.25rem;
  flex: 1;
  border-radius: 20px;
  /* background: white; */
}

.spponsor-link {
  color: var(--clr-text);
  font-weight: 600;
  text-decoration: none;
}

.spponsor-link:hover {
  border-bottom: 1px solid var(--clr-link);
}

.sponsor-desc {
  color: var(--clr-text-light);
  margin-top: 10px;
}

.hints-box {
  text-align: left;
  line-height: 1.5;
}

.score-container {
  font-family: ClashGrotesk;
  background: none;
  padding: 0;
  text-align: left;
}

.score-container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.score-container__buttons {
  margin: 20px -10px 0;
}

.score-container__buttons .button {
  flex: 1 1;
  margin: 0 10px;
}

.score-helper {
  line-height: 1.5;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid var(--clr-border);
}

.score-container__score-type {
  font-weight: 500;
}

.score-helper a {
  color: var(--clr-link);
}

.score-container__score {
  font-size: var(--font-size-2);
  /* font-size: 18px; */
  font-weight: bold;
  color: var(--clr-text);
}

.score-container__spacer {
  height: 1px;
  flex: 1;
  margin: 0 1rem;
  background: var(--clr-border);
  border-radius: 1px;
}

.colors-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.colors-list__color {
  background-color: rgb(var(--rgb-tile-top));
  flex: 0 0 calc(33.33% - 10px);
  padding: 0.4rem 0.8rem 0.4rem 2.6rem;
  cursor: pointer;
  color: var(--clr-text-light);
  border: 0;
  border-radius: 50px;
  text-align: right;
  position: relative;
  text-transform: uppercase;
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  display: flex;
  transition: 0.25s ease;
}
.colors-list__color:after {
  content: '';
  position: absolute;
  background: var(--color);
  border-radius: 50px;
  height: 1.2rem;
  width: 1.2rem;
  left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25), 0 0 0 2px rgba(0, 0, 0, 0.8);
  /* transition: 0.25s cubic-bezier(0.62, -0.44, 0.35, 1.48); */
  transition: 0.25s ease;
}
.colors-list__color:hover,
.colors-list__color:focus {
  transform: scale(1.05);
}
.colors-list__color:hover:after,
.colors-list__color:focus:after {
  transform: translateY(-50%) scale(1.35);
}
.colors-list__color:active {
  transform: scale(0.95);
}

.cm-editor {
  font-family: inherit;
  font-size: 1rem;
  background: transparent !important;
  height: auto;
}
.cm-scroller {
  font-family: inherit !important;
  line-height: 1.6 !important;
}
.container__item--editor [class^='cm-theme'] {
  position: relative;
  height: 100%;
}
.container__item--editor .cm-editor {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 0.25rem;
  padding-left: 0.625rem;
}

.cm-gutters {
  background: transparent !important;
}

.cm-lineNumbers {
  color: #5a646f !important ;
}
.cm-content {
  overflow-x: hidden;
  font-size: calc(1rem + 0.2em * var(--fontSize));
}

.CodeMirror-vscrollbar {
  z-index: 3;
}

/* MONACO */
.monaco-editor {
  margin-top: 1rem;
}

.monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input,
.monaco-editor .margin {
  background-color: transparent !important;
}

.container__item--targets .item__content,
.container__item--battles .item__content {
  padding: 0;
}

.container__item--battles {
  background: var(--clr-centered-box-bg);
}

.battles-container {
  padding: 2.5rem;
}

.targets-container,
.leading-targets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-gap: 1.5rem;
  /* padding: 2rem; */
}

.targets-container {
  pointer-events: none;
}

.targets-container:hover .target-tile {
  opacity: 0.5;
}

.leading-targets {
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  grid-gap: 2rem;
  padding: 0;
}

.target-tile,
.leading-targets__item {
  --corner-radius: 1rem;

  text-decoration: none;
  border-radius: 5px;
  background: var(--clr-target-tile-bg);
  position: relative;
  transition: opacity 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease,
    z-index 0.25s 0.25s ease;

  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    rgba(var(--rgba-tile-bottom)) 101.76%
  );
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.1), 0px 24px 74px rgba(0, 0, 0, 0.15),
    inset 1px 1px 3px rgba(255, 255, 255, 0.1);
  border-radius: var(--corner-radius);
  padding: 0.6rem;
}

.target-tile__image,
.leading-targets__image {
  display: block;
  width: 100%;
  border-radius: 5px 5px 0 0;
  border: 3px solid var(--target-border-color);
  border-radius: calc(var(--corner-radius) * 0.5);
  aspect-ratio: var(--target-aspect-ratio);
}

.leading-targets--2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  overflow: hidden;
  align-items: center;
  gap: 0rem;
  width: 100%;
}
.leading-targets--2-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16ch, 1fr));
  gap: 0.5rem;
}

.leading-targets__item {
  box-shadow: -20px 0 30px 0 rgb(0 0 0 / 40%);
  transform-origin: center top;
}
.leading-targets__item,
.leading-targets__image {
  border-radius: 0 !important;
}

.leading-targets--2 .leading-targets__item {
  box-shadow: none;
  padding: 0;
  background: none;
}

.leading-targets--2 .leading-targets__image {
  border: 0;
  border-radius: 8px;
}

.leading-targets--2-grid .leading-targets__item {
  width: auto;
}

.target-tile {
  z-index: 1;
  pointer-events: auto;
}

.public-target-message,
.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: var(--clr-target-tile-bg);
  z-index: -1;
  border-radius: 5px;
  font-size: var(--font-size-0);
  opacity: 0;
  transition: 0.4s ease;
}

.public-target-message:after,
.tooltip:after {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -5px;
  left: calc(50% - 10px);
  background: var(--clr-target-tile-bg);
  transform: rotate(45deg);
}

.tooltip {
  --clr-target-tile-bg: #111;
  z-index: 0;
  padding: 1rem;
  min-width: 250px;
}

.public-target-message p {
  padding: 15px 20px 20px;
}

.target-tile--public:not(.target-tile--dummy):hover .public-target-message,
.target-tile--public:not(.target-tile--dummy):focus-within
  .public-target-message,
.tooltip-wrap:hover .tooltip {
  transform: translateY(calc(-100% - 10px));
  /* box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.5); */
  opacity: 1;
}

.target-tile--glowing {
  box-shadow: var(--glow-shadow) !important;
}

.tooltip--right {
  left: auto;
  top: 0;
  right: 0;
}
.tooltip--right:after {
  bottom: calc(50% - 10px);
  left: -5px;
}
.tooltip-wrap:hover .tooltip--right {
  transform: translateX(calc(100% + 10px));
}

.target-tile__info {
  /* background: var(--clr-target-tile-bg); */
  /* border-radius: 0 0 5px 5px; */
}

.leading-targets__image {
  min-height: 0;
  border-radius: 5px;
}

.leader-targets__rank {
  font-size: var(--font-size-3);
  font-weight: bold;
  line-height: 1.1;
}

.leading-targets__item button {
  margin: 0 1.25rem 1.25rem;
  width: calc(100% - 2.5rem);
}

.target-tile--dummy {
  pointer-events: none;
}

.target-tile:not(.target-tile--dummy):hover,
.target-tile:not(.target-tile--dummy):focus-within {
  /* box-shadow: 0 0 0 2px var(--clr-brand), 0px 10px 50px rgba(0, 0, 0, 0.5); */
  transform: scale(1.05);
  transition: opacity 0.25s ease, box-shadow 0.25s ease, transform 0.25s ease,
    z-index 0s 0s ease;
  z-index: 2;
  opacity: 1;
}

.target-tile:not(.target-tile--dummy):hover .target-top-info,
.target-tile:not(.target-tile--dummy):focus-within .target-top-info,
.leading-targets__item:hover .target-top-info {
  opacity: 0;
  visibility: hidden;
}

.target-tile--daily:not(.target-today .target-tile--daily):not(
    .target-primary .target-tile--daily
  ) {
  width: 240px;
  background: transparent;
  box-shadow: none;
  border: 2px solid var(--clr-border);
}

.target-today .target-tile--daily {
  box-shadow: var(--glow-shadow);
}

.target-tile .button {
  z-index: 2;
}

.target-today .target-tile--daily {
  width: 320px;
}

.daily-target-text {
  font-size: 1rem;
}

.daily-target-text__title {
  font-family: 'ClashGrotesk';
  font-weight: 500;
  color: var(--clr-text-lightest-final);
}

.daily-target-text__score {
  font-family: 'IBM Plex Mono';
  font-weight: 500;
  color: var(--clr-text-light);
}

.target-today .daily-target-text__score {
  color: var(--clr-brand);
}

.shadow-link {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.target-top-info {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}
.target-top-info--invert {
  left: 10px;
  right: auto;
}

.target-top-info__pill {
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  font-weight: 600;
  font-size: var(--font-size-0);
  display: inline-flex;
  align-self: center;
  align-items: center;
  text-transform: uppercase;
}

.target-top-info__pill img {
  height: 14px;
  margin: 0.1rem 0;
}

.target-top-info__pill:last-of-type {
  margin-left: 5px;
}

.target-leader {
  margin-top: 20px;
  margin-left: 0;
  color: var(--clr-text-light);
  font-weight: normal;
}

.button--leaderboard-bottom {
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  text-align: center;
}

.target-highscore {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--clr-text-light);
  padding: 15px 20px 15px;
  position: relative;
  z-index: 1;
}

.target-tile-header {
  padding: 0.2rem 1.25rem;
  background: var(--clr-tile-header);
  font-size: var(--font-size-0);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--clr-text-light);
}

.target-highscore__right {
  line-height: 1.5rem;
  min-width: 0;
}

.target-highscore__username {
  font-weight: bold;
  color: var(--clr-text);
}

.target-highscore__avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

.target-attempts {
  border-radius: 8px;
  background: rgb(var(--rgb-tile-active-bottom));
  font-size: var(--font-size-0);
  color: var(--clr-text);
  padding: 0.5rem 1rem;
}

.target-attempts__item--not-played {
  color: var(--clr-text-lightest-final);
}

.target-attempts__item {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  /* padding-left: 30px;
  background: url(/images/icons/rank.svg) no-repeat;
  background-position: left center; */
}

.target-attempts__item:last-of-type {
  padding-bottom: 0;
}

.target-attempts__item--submissions {
  background-image: url(/images/icons/submissions.svg);
}
.target-attempts__item--rank {
  background-image: url(/images/icons/rank.svg);
}
/* .target-attempts__item--score {
  background-image: url(/images/icons/score.svg);
} */
/* 
.char-with-icon:after {
  content: '';
  position: relative;
  background: url(/images/icons/char.svg) no-repeat;
  width: 12px;
  height: 10px;
  top: -1px;
  margin-left: 5px;
  display: inline-block;
} */

.container__options {
  margin: 2.5rem 2.5rem 0;
  border-radius: 5px;
  color: var(--clr-text);
}

.section-helper {
  color: var(--clr-text-light);
  /* opacity: 0.75; */
  /* line-height: 24px; */
}

.leaderboard__user-list,
.submissions-list {
  margin-top: 30px;
  list-style: none;
  padding: 1.25rem;
}

.leaderboard__user-list {
  padding: 0;
  margin-top: 0;
}

.leaderboard__user,
.section-helper--no-score {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-family: ClashGrotesk;
  font-weight: 500;
  color: var(--clr-text-lightest-final);
  /* margin: 0.7rem 0; */
}

.leaderboard__user {
  padding: 0.5rem;
  font-family: 'ClashGrotesk';
  gap: 0.5rem;
}

.section-helper--no-score {
  border: 1px solid var(--clr-border-brighter);
  border-radius: 16px;
}

.leaderboard__user-info {
  flex-grow: 1;
  min-width: 0;
  white-space: nowrap;
}

.leaderboard__user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0;
  transition: transform 0.3s ease;
  display: inline-block;
}

.leaderboard__user-score,
.leaderboard__user-rank {
  color: var(--clr-text-light);
}

.leaderboard__user-score {
  font-size: var(--font-size-0);
  font-family: 'IBM Plex Mono';
  color: var(--clr-text-light);
}

.leaderboard__user-name {
  color: var(--clr-link);
  font-weight: 600;
  margin-bottom: 2px;
}

.leaderboard__user-rank {
  font-size: var(--font-size-4);
  display: inline-block;
}

/* .leaderboard__user--1,
.leaderboard__user--2,
.leaderboard__user--3 {
  background: rgba(255, 223, 0, 0.15);
  border-radius: 5px;
}

.leaderboard__user--2 {
  background: rgba(192, 192, 192, 0.15);
}

.leaderboard__user--3 {
  background: rgba(205, 127, 50, 0.15);
}

.leaderboard__user-rank--1,
.leaderboard__user-rank--2,
.leaderboard__user-rank--3 {
  height: 2rem;
  background: var(--gold-medal-img) no-repeat;
  background-position: center;
  background-size: contain;
  color: transparent;
}
.leaderboard__user-rank--2 {
  background-image: var(--silver-medal-img);
}

.leaderboard__user-rank--3 {
  background-image: var(--bronze-medal-img);
}
 */

.append-star,
.append-friend {
  color: var(--clr-brand);
  /* padding-left: 1em;
  background: var(--star-img) no-repeat;
  background-size: 1rem; */
  /* background-position: center left; */
  /* font-weight: bold; */
}

.append-friend {
  color: white;
  background-image: url(/images/icons/friend.svg);
  background-size: 1.2rem;
  padding-left: 25px;
}

.underlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 20vw;
  min-width: 30rem;
  background: var(--clr-sidebar-bg);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 0.2s ease, visibility 0.2s ease,
    transform 0.4s cubic-bezier(1, 0.04, 0.21, 0.93);
}
.sidebar--right {
  left: auto;
  right: 0;
  transform: translateX(100%);
}
.sidebar--noHeaderSpace {
  top: 0;
}

.sidebar__content {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sidebar .item__header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.underlay {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.sidebar-container--visible .underlay,
.sidebar-container--visible .sidebar {
  opacity: 1;
  visibility: visible;
}

.sidebar-container--visible .sidebar {
  transform: translateX(0);
}

.sidebar__close {
  border: 0;
  position: absolute;
  right: -60px;
  top: 20px;
  background: none;
}
.sidebar__close:not(.no-icon) {
  height: 33px;
  width: 33px;
  background: url(/images/close.svg) no-repeat;
}
.sidebar--right .sidebar__close {
  left: -60px;
  right: auto;
}
.submissions-list {
  padding: 0;
  width: 100%;
}

.submissions-list li {
  margin-bottom: 20px;
}

.submissions-list .dropdown-container {
  z-index: auto;
}

.submissions-list .dropdown-menu {
  width: 100%;
}

.submissions-list .dropdown-btn {
  display: block;
  text-align: left;
}

.submissions-list__code {
  word-break: break-word;
  /* padding: 1em 0; */
  width: 100%;
  /* color: var(--clr-text-light); */
  font-family: 'IBM Plex Mono';
  letter-spacing: 0;
}
/* hide line numbers in code blocks in a list */
.submissions-list__code .cm-gutters {
  display: none;
}
.submissions-list__code .cm-editor {
  max-height: 30ex;
}
.submissions-list__time {
  font-weight: 400;
}
.submissions-list__time--absolute {
  opacity: 0.75;
  display: none;
  font-weight: 500;
}

.submissions-list__time--relative {
  opacity: 0.75;
  font-weight: 500;
}

.top-submission-container {
  background: var(--clr-dropdown-bg);
  border-radius: 5px;
  padding: 1rem;
}

.submissions-dropdown {
  background-position: center right 1rem;
  border-radius: 8px;
  padding: 0.75rem 1rem;
}

.submissions-list__item button:hover .submissions-list__time--relative,
.submissions-list__item button:focus .submissions-list__time--relative {
  display: none;
}
.submissions-list__item button:hover .submissions-list__time--absolute,
.submissions-list__item button:focus .submissions-list__time--absolute {
  display: inline-block;
}

.avatar-link {
  font-size: 0;
  transition: transform 0.125s ease;
}

.avatar-link:has(.pro-badge):hover {
  transform: scale(1.1);
}

.top-submission__author {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--clr-border-brighter);
}

.top-submission__author__score {
  font-size: var(--font-size-0);
}

.faq-section {
  margin-bottom: 1rem;
  max-width: 80ch;
}
.faq-section__question {
  font-weight: 500;
  cursor: pointer;
}
.faq-section[open] .faq-section__question {
  color: var(--clr-brand);
}
.faq-section__question-link {
  margin-left: 1rem;
  color: var(--clr-link);
  visibility: hidden;
}
.faq-section[open] .faq-section__question-link {
  visibility: visible;
}
.faq-section__answer {
  margin-top: 0.75rem;
  margin-left: 0rem;
  padding: 1.5rem;
  border-radius: 16px;
  color: var(--clr-text-light);
  background: var(--clr-box-bg);
  line-height: 1.6;
}
.faq-section[open] .faq-section__answer {
  /* box-shadow: 0 0 0 1px var(--clr-brand); */
}
.faq-section__answer p,
.dropdown-menu--text p {
  margin-bottom: 1.25rem;
}
.faq-section__answer p:last-of-type,
.dropdown-menu--text p:last-of-type {
  margin-bottom: 0;
}

.dropdown-menu--text p:first-of-type {
  margin-top: 0;
}

.faq-section__answer strong,
.dropdown-menu--text strong {
  color: var(--clr-text);
}

.container__item--scrollable {
  overflow-y: auto;
}

.container__item--scrollable .item__header {
  top: 0;
  position: sticky;
  z-index: 2;
}

.filter {
  margin: 0 10px;
}

.leaderboard-filter,
.generic-filter {
  padding: 1rem 0.75rem;
  margin: 0 0 1.25rem;
  border: 1px solid var(--clr-border-brighter);
  border-radius: 5px;
  display: flex;
  line-height: 2;
  color: var(--clr-link);
}

.generic-filter {
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.filter__button {
  margin: 0 10px;
  flex: 1;
  position: relative;
}

.filter__button input {
  margin-right: 8px;
}

.filter__button label {
  cursor: pointer;
  display: inline-block;
}

.leaderboard-filter .filter__button input[checked] + label:after {
  position: absolute;
  content: '';
  left: -10px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  background: var(--clr-active-filter);
}

.coming-soon {
  margin: 5px 0 40px;
  text-align: center;
  color: var(--clr-text-light);
}

.editor-console {
  padding: 10px 60px 10px 20px;
  font-size: var(--font-size-0);
  line-height: 1.5;
  display: none;
  color: var(--clr-text);
}

.editor-console--error {
  display: block;
  background: rgba(248, 60, 59, 0.2) url(/images/icons/error.svg) no-repeat;
  background-position: right 20px center;
}

.editor-console--warning {
  display: block;
  background: rgba(255, 103, 0, 0.2) url(/images/icons/warning.svg) no-repeat;
  background-position: right 20px center;
}

/* 3RD PARTY OVERRIDES */
/* Because hint.css sets element to inline-block, breaking .button */

[class*='hint--']:before,
[class*='hint--']:after {
  /* to prevent focus outline on the tooltip also */
  display: none;
}

[class*='hint--']:after {
  font-size: 1rem !important;
  font-family: inherit;
  background: #000 !important;
}

[class*='hint--top']:before {
  border-top-color: #000 !important;
}
[class*='hint--bottom']:before {
  border-bottom-color: #000 !important;
}

[class*='hint--']:hover:before,
[class*='hint--']:hover:after {
  display: block;
}

[class*='hint--'].button {
  display: inline-flex;
}

.Toastify__toast {
  border-radius: 0.5rem;
  font-family: 'ClashGrotesk';
  font-weight: 500;
  letter-spacing: 0.3px;
  word-spacing: 3px;
}
/* Remove fat cursor */
.CodeMirror-cursor {
  border-left-width: 2px !important;
  border-right: 0 !important;
}
/* Make comments color contrast accessible */
span.cm-comment,
.ͼ1e {
  color: #8782a1 !important;
}
.cm-s-duotone-dark.CodeMirror {
  color: #cac6dc;
}
.cm-s-duotone-dark span.cm-error,
.cm-s-duotone-dark span.cm-invalidchar {
  color: #d18d8d !important;
}
.button--login {
  margin: 0.5em;
}
.button--github {
  background: #24292e;
  color: #eee;
}
.button--twitter {
  --clr-btn-hover: #bce2ff;

  background: #55acee;
  color: black;
}
.button--google {
  --clr-btn-hover: white;

  background: #fcfcfc;
  color: #333;
}

/** Settings **/
.settings-container {
  padding: 2.5rem;
}

.settings-tabs {
  padding-right: 3.75rem;
}

.settings-tabs ul {
  list-style: none;
}

.settings-tabs li {
  margin-bottom: 0.75rem;
}

.settings-tabs button {
  display: inline-flex;
  align-items: center;
  color: var(--clr-link);
  font-size: var(--font-size-2);
  text-decoration: none;
  padding: 0.75rem 5rem 0.75rem 1.25rem;
  border-radius: 5px;
  background: transparent;
  border: 0;
  cursor: pointer;
}

.settings-tab__active button {
  background: var(--clr-tab-bg-selected);
}

.settings-tab-content {
  flex: 1;
}

.input-container label,
.label {
  border-radius: 5px;
  font-size: var(--font-size-1);
  display: block;
  color: var(--clr-link);
  font-family: ClashGrotesk;
  font-weight: 500;
  letter-spacing: 0.2px;
}
.input-container label {
  margin-bottom: 0.5rem;
}

.input-container {
  margin-bottom: 2rem;
}
.input-container--no-margin {
  margin-bottom: 0;
}
.new-input-container .input-container {
  margin-bottom: 0;
}

.input-container--full-width {
  width: 100%;
}
.input-container input {
  width: 100%;
  border-radius: 50px;
  color: var(--clr-text);
}
.input-container input[type='file'],
.input-container input[type='checkbox'],
.input-container input[type='radio'] {
  width: auto;
  max-width: 100%;
  position: relative;
  top: -0.1em;
}
.input--otp {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  padding: 0;
  font-size: 5rem;
  border-radius: 0 !important;
  --pad: 1rem;
  --gap: 1rem;
  --w: calc(2 * var(--pad) + 1ch);
  --letter-spacing: calc(var(--pad) * 2 + var(--gap));
  --input-bg: var(--clr-input);

  width: calc(
    var(--w) * var(--numDigits) + var(--gap) * (var(--numDigits) - 1) +
      var(--letter-spacing)
  ) !important;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    var(--input-bg) var(--w),
    transparent 0,
    transparent calc(var(--w) + var(--gap)),
    var(--input-bg) calc(var(--w) + var(--gap)),
    var(--input-bg) calc(var(--w) * 2 + var(--gap)),
    transparent 0,
    transparent calc(var(--w) * 2 + var(--gap) * 2),
    var(--input-bg) calc(var(--w) * 2 + var(--gap) * 2),
    var(--input-bg) calc(var(--w) * 3 + var(--gap) * 2),
    transparent 0,
    transparent calc(var(--w) * 3 + var(--gap) * 3),
    var(--input-bg) calc(var(--w) * 3 + var(--gap) * 3),
    var(--input-bg) calc(var(--w) * 4 + var(--gap) * 3),
    transparent 0,
    transparent calc(var(--w) * 4 + var(--gap) * 4),
    var(--input-bg) calc(var(--w) * 4 + var(--gap) * 4),
    var(--input-bg) calc(var(--w) * 5 + var(--gap) * 4),
    transparent 0,
    transparent calc(var(--w) * 5 + var(--gap) * 5),
    var(--input-bg) calc(var(--w) * 5 + var(--gap) * 5),
    var(--input-bg) calc(var(--w) * 6 + var(--gap) * 5),
    transparent 0
  );
  border-radius: 0;
  letter-spacing: var(--letter-spacing);
  text-indent: var(--pad);
  font-family: 'IBM Plex Mono';
}
.username-form {
  padding-bottom: 2.5rem;
  border-bottom: 1px solid var(--clr-border);
  margin-bottom: 2.5rem;
}

.input-hint {
  font-size: var(--font-size-1);
  color: var(--clr-text-light);
  padding-top: 0.5rem;
  font-family: ClashGrotesk;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.input-hint:first-of-type {
  margin-top: 0.6rem;
}

.input-with-prefix,
.input-with-button {
  display: flex;
}

input,
textarea,
.input-prefix {
  padding: 0.7rem 1rem;
  background: var(--clr-input);
  border: 1px solid var(--clr-border-brighter);
  color: inherit;
  font: inherit;
  color: var(--clr-text);
  font-family: ClashGrotesk;
  font-weight: 500;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: middle;
}

.input-with-prefix input,
.input-with-button button {
  border-radius: 0 50px 50px 0;
}

.input-with-button button {
  white-space: nowrap;
}

.input-with-button input {
  border-radius: 50px 0 0 50px;
}

.input-prefix {
  border-radius: 50px 0 0 50px;
  border-right: 0;
  color: var(--clr-text-lightest);
  white-space: nowrap;
}

.input-prefix svg,
.input-prefix span {
  vertical-align: middle;
}

label input[type='checkbox'],
label input[type='radio'] {
  margin-right: 0.5rem;
  padding: 0;
}
.select {
  position: relative;
  padding: 2px;
  background: var(--clr-input);
  border: 1px solid var(--clr-border-brighter);
  border-radius: 50px;
  font-family: ClashGrotesk;
  font-weight: 500;
}
.select select {
  appearance: none;
  background: var(--clr-input);
  border: 0;
  padding: 0.75rem 1rem;
  padding-right: 2rem;
  font: inherit;
  color: var(--clr-text);
  width: 100%;
  border-radius: 50px;
  background-image: url(/images/chevron-bottom.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: right 16px center;
}

/* SELECT ARROW */
/* .select:after {
  content: '';
  position: absolute;
  right: 1rem;
  top: 1.4rem;
  width: 0.8em;
  height: 0.5em;
  opacity: 0.6;
  background-color: currentColor;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
} */
.validation-message {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
.name-link {
  text-decoration: none;
  color: inherit;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: ClashGrotesk;
  font-weight: 600;
}

@media screen and (min-width: 2500px) {
  .container--pro-page .container__item {
    width: 2000px;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 0.8em;
  }
}

/* because arc browser zaps .sponsor-container */
.sponsor-containerr {
  position: relative;
  margin-top: 20px;
  z-index: 0;
  min-height: 240px;
  padding-bottom: 1rem;
}

#carbonads,
[data-ea-publisher],
.no-sponsor {
  position: absolute;
  top: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  font-size: 12px;
}

.no-sponsor {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Lucida Console',
    monospace;
  color: var(--clr-text-light);
  font-size: 16px;
  line-height: 25px;
  opacity: 0;
  animation: appear-after-2-seconds 0.1s ease 3s forwards;
  z-index: -1;
}

#carbonads a {
  text-decoration: none;
}

#carbonads span {
  position: relative;
  display: block;
  overflow: hidden;
}

.carbon-img {
  float: left;
  margin-right: 1em;
}

.carbon-img img {
  display: block;
}

.carbon-text {
  display: block;
  float: left;
  max-width: calc(100% - 130px - 1em);
  text-align: left;
  color: var(--clr-text);
}

.carbon-poweredby {
  position: absolute;
  left: 142px;
  bottom: 0;
  display: block;
  font-size: 8px;
  color: var(--clr-text-light);
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 1px;
}

[data-ea-publisher] {
  max-width: 100% !important;
}

[data-ea-publisher] .ea-callout {
  margin: 0 !important;
}
[data-ea-publisher] .ea-content {
  margin: 0 !important;
}

[data-ea-publisher]:empty {
  display: none;
}

[data-ea-publisher]:not(:empty) ~ .no-sponsor,
#carbonads ~ .no-sponsor {
  display: none;
}

@keyframes appear-after-2-seconds {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** Leaderboards page **/
.leaderboard-table .name-link {
  max-width: 35vw;
}
.leaderboard-leaders {
  display: flex;
  margin: 0 -1rem;
}

.leader__name {
  font-size: var(--font-size-2);
  color: var(--clr-link);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.leader--1 {
  background-image: var(--gold-medal-img);
  background-color: rgba(255, 223, 0, 0.15);
}

.leader--2 {
  background-image: var(--silver-medal-img);
  background-color: rgba(192, 192, 192, 0.15);
}

.leader--3 {
  background-image: var(--bronze-medal-img);
  background-color: rgba(205, 127, 50, 0.15);
}

.leaderboard-table {
  --border-radius: 1rem;
  width: 100%;
  max-width: 90ch;
  border-spacing: 0 1rem;
  border-collapse: separate;
}

.leaderboard-table tr {
  text-align: left;
  border: 0;
  border-radius: var(--border-radius);
  padding: 1rem 0;

  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    rgba(var(--rgba-tile-bottom)) 101.76%
  );

  box-shadow: var(--hard-shadow);
}

.leaderboard-table tr:nth-of-type(even) {
  /* background: var(--clr-box-bg); */
}

.leaderboard-table td {
  padding: 0.7rem 1rem;
  text-align: right;
}

.leaderboard-table td:last-child,
.leaderboard-table th:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.leaderboard-table td:first-child,
.leaderboard-table th:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.leaderboard-table td img,
.leaderboard-table td svg {
  vertical-align: middle;
}

.battles-button-container .button {
  margin-bottom: 1.25rem;
}

.battles-button-container .button:last-of-type {
  margin-bottom: 0;
}

.leaderboard-table td[data-column='Rank'] {
  font-size: var(--font-size-3);
  color: var(--clr-text-lightest-final);
}

.leaderboard-table td[data-column='Player Details'] {
  text-align: left;
}

.leaderboard-selector {
  border: 1px solid var(--clr-border);
  padding: 1.5rem 2rem;
}

.leaderboard-selector {
  border-top: 0;
}

.leaderboard-selector:first-of-type {
  border-radius: 5px 5px 0 0;
  border-top: 1px solid var(--clr-border);
}

.leaderboard-selector:last-of-type {
  border-radius: 0 0 5px 5px;
}

.leaderboard-selector input {
  margin-right: 1rem;
  position: relative;
  top: -2px;
}

.leaderboard-selector .select {
  margin-top: 0.75rem;
}

.item__content--fixed-width {
  max-width: 960px;
}

.item__content--fixed-width-large {
  max-width: 80rem;
}

.battle-status {
  display: inline-block;
  padding: 0.2rem 1rem;
  border-radius: 4px;
}
.battle-status--minimal {
  border-radius: 50%;
  padding: 0;
  width: 1em;
  height: 1em;
}

.all-time-link {
  margin-top: 2rem;
}

/* notifications */
.notifications-count {
  background: var(--clr-red);
  border-radius: 10px;
  margin-left: 0.5rem;
  padding: 0 5px;
}

.dropdown-menu--notifications {
  width: 500px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
}

.notification-item {
  padding: 1.25rem;
  position: relative;
  border-bottom: 1px solid var(--clr-border-brighter);
}

.notification-item:last-of-type {
  border-bottom: 0;
}

.notification-item__content {
  display: flex;
}

.notification-item__image {
  flex-grow: 0;
  margin-right: 0.75rem;
}

.notification-item__image img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.notification-item__text {
  line-height: 1.4;
  color: var(--clr-text);
}

.notification-item:hover .notification-item__text {
  color: var(--clr-link);
}

.notification-item__time {
  margin-top: 0.25rem;
  color: var(--clr-text-lightest);
}

.friends-empty {
  text-align: center;
  margin: 0 0 2rem;
  padding: 1rem;
  border: 1px solid var(--clr-border);
  border-radius: 16px;

  font-family: ClashGrotesk;
  max-width: 360px;
}

.friends-empty img {
  width: 100%;
  border-radius: 8px;
}

.friends-empty__title {
  font-size: var(--font-size-2);
  font-weight: 600;
  color: var(--clr-text);
  margin: 1.25rem 0 0.75rem;
}

.friends-empty__desc {
  color: var(--clr-text-lightest-final);
  line-height: 1.5;
  font-weight: 500;
  padding: 0 0.5rem 0.5rem;
}

.scoring-tip {
  padding-left: 5rem;
  position: relative;
  margin-bottom: 5rem;
  counter-increment: tip;
}

.scoring-tip:before {
  content: '#' counter(tip);
  position: absolute;
  left: -0.25rem;
  font-size: var(--font-size-3);
  color: var(--clr-text-lightest);
  font-weight: bold;
  top: -5px;
  background: var(--clr-banner-bg);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoring-tip__code-container {
  display: flex;
  margin: 0 -1.25rem;
}

.scoring-tip__desc {
  margin: 0 0 1.5rem;
  color: var(--clr-text);
}

.scoring-tip__code {
  flex: 1;
  margin: 0 1.25rem;
  border-radius: 5px;
  padding: 1rem 1.75rem;
  background: var(--clr-box-bg);
}
.scoring-tip__code .inner-header {
  margin: 0 0 1rem;
}

.scoring-tip__title {
  margin-bottom: 1.25rem;
}

.scoring-tip__code .inner-header *:before {
  background: var(--clr-box-bg);
}

.tip-disclaimer {
  color: var(--clr-text-lightest);
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid var(--clr-border);
}

/** Stats Page **/
.target-stats {
  background: var(--clr-box-bg);
  padding: 2rem 2rem 2rem 0;
  border-radius: 10px;
  overflow-x: auto;
  /* display: flex; */
  /* min-height: 470px; */
}

.target-stats__desc {
  background: var(--clr-box-bg);
  padding: 0 2rem;
  display: flex;
  align-tracks: top;
  gap: 2rem;
}

.target-stats__desc .input-container {
  margin-bottom: 0;
}

.target-stats__desc label {
  font-size: var(--font-size-1);
  margin-bottom: 0.5rem;
}

.target-stats__desc select {
  width: 100%;
}

.target-stats__desc .performance__diff {
  text-align: center;
  border-radius: 5px;
  padding: 0.5rem;
  /* margin: 1rem 0 3rem; */
  font-size: var(--font-size-1);
  font-weight: normal;
}

.target-stats__title {
  font-weight: bold;
  color: var(--clr-text);
  font-size: var(--font-size-3);
}

.performance-tiles {
  margin: 0 -1.5rem;
  white-space: nowrap;
}

.performance-tile {
  display: inline-block;
  background: var(--clr-target-tile-bg);
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 1.5rem;
  min-width: 150px;
}

.performance__leader,
.performance__you {
  padding: 1rem;
}

.performance__avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.performance__target {
  display: block;
  width: 150px;
  position: relative;
}

.performance__target img {
  display: block;
  width: 100%;
}

.performance__user-title,
.rival__targets {
  font-size: var(--font-size-0);
  text-transform: uppercase;
  color: var(--clr-text-light);
}

.performance__user-score,
.rival__name {
  font-weight: bold;
}

.performance__diff {
  --color-1: #85363a;
  --color-2: #955154;
  background-image: linear-gradient(
    45deg,
    var(--color-1) 25%,
    var(--color-2) 0,
    var(--color-2) 50%,
    var(--color-1) 0,
    var(--color-1) 75%,
    var(--color-2) 0,
    var(--color-2) 100%
  );
  background-size: 10px 10px;
  font-size: var(--font-size-3);
  font-weight: bold;
}

.performance-tile--leader {
  background: rgba(22, 215, 111, 0.2);
}

.performance-tile--leader .performance__diff {
  --color-1: #366f56;
  --color-2: #51826d;
}

.performance-tile--not-played .performance__diff {
  opacity: 0.5;
}

.friends-container {
  margin: 1rem -1rem 0;
}

.rival {
  padding: 2rem;
  background: var(--clr-box-bg);
  display: inline-flex;
  margin: 1rem;
  border-radius: 5px;
  align-content: center;
  line-height: 1.6;
}

.rival__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem;
  display: block;
}

.subscribe-box {
  text-align: center;
  background: var(--clr-box-bg);
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 10px;
}

.subscribe-box h3 {
  margin-bottom: 1rem;
}

[data-reach-dialog-overlay] {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: var(--z-index-modal);
}

[data-reach-dialog-content] {
  width: 60vw;
  max-width: calc(100vw - 4rem);
  margin: 10vh auto;
  background: var(--clr-box-bg);
  border-radius: 10px;
  padding: 0;
  outline: none;
  position: relative;
  font-family: ClashGrotesk;
  font-weight: 500;
}

.modal--small {
  width: 45vw;
}
.modal--content {
  width: max-content;
}

@media (max-width: 800px) {
  [data-reach-dialog-content] {
    width: auto;
  }
}

.modal--text-center {
  text-align: center;
}

.firstScoreIcon,
.openBookIcon {
  height: 3rem;
  width: 3rem;
  background: var(--star-img);
  background-size: contain;
  background-repeat: no-repeat;
  margin: 1rem auto 2rem;
}

.openBookIcon {
  background-image: url(/images/open-book.svg);
  background-position: center;
}

[data-reach-dialog-content] .react-codemirror2 {
  background: var(--clr-input);
  border: 1px solid var(--clr-border-brighter);
  border-radius: 5px;
  padding: 1rem;
}

.battle-rewards {
  margin: 2rem 0 1rem;
}

.reward-tile {
  padding: 3.2rem 1.5rem 1rem 1.5rem;
  background: var(--clr-bg);
  margin: 0 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  background-repeat: no-repeat;
  background-position: top 1rem center;
  background-size: 1.5rem;
  display: inline-flex;
  align-items: center;
}

.reward-tile--gold {
  background-image: var(--gold-medal-img);
  background-color: rgba(255, 223, 0, 0.15);
}
.reward-tile--silver {
  background-image: var(--silver-medal-img);
  background-color: rgba(192, 192, 192, 0.15);
}

.reward-tile--bronze {
  background-image: var(--bronze-medal-img);
  background-color: rgba(205, 127, 50, 0.15);
}

.reward-tile--regular {
  background-image: var(--star-img);
}

.badge--new {
  display: inline-block;
  background-color: #2b7718;
  color: white;
  margin: 0 0.5rem;
  padding: 1px 10px;
  border-radius: 4px;
  border: 1px solid #c2953b;
  text-transform: uppercase;
  font-size: var(--font-size-0);
}
.badge--new:before {
  content: 'New';
}

/* Learn Page */
.learn-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(50px);
  padding: 4rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 1.6em;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .learn-modal {
    font-size: 1em;
  }
}
.learn-content {
  max-width: 60ch;
}

.learn-content p {
  margin-top: 20px;
  line-height: 1.5;
}

video {
  filter: blur(50px);
}
video[controls] {
  filter: none;
}

/** JOBS **/
.jobs-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 2rem;
}

.jobs-list__item {
  background: var(--clr-target-tile-bg);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.jobs-list__item__highlight {
  border: 1px solid var(--clr-brand);
}

.jobs__company-info {
  padding: 1.5rem 1.5rem 0.75rem;
  border-bottom: 1px solid var(--clr-border-brighter);
  flex: 1;
}

.jobs__company-logo {
  width: 50px;
  border-radius: 50%;
}

.jobs__company-name {
  font-weight: bold;
  color: var(--clr-heading);
}

.jobs__company-desc {
  font-size: var(--font-size-0);
  line-height: 1.6;
  color: var(--clr-text-lightest);
  margin-top: 1rem;
}

.job-meta-info {
  padding: 1rem 1.5rem;
}

.job-meta-info p,
.job-meta-info a {
  font-size: var(--font-size-0);
  color: var(--clr-heading);
}

.jobs__company-info .target-top-info__pill {
  font-size: var(--font-size-0);
  font-weight: normal;
}

.job-tile-pills {
  display: flex;
  margin: 1rem 0rem 0.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.job-tile-pills > div {
  margin-right: 0.5rem;
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
  background: var(--clr-banner-bg);
}

/* /post-job */
.post-job-pricing {
  margin-bottom: 2rem;
  border: 1px solid var(--clr-border-brighter);
  border-radius: 10px;
}

.post-job-pricing .generic-filter {
  border: 0;
  border-top: 1px solid var(--clr-border-brighter);
  margin: 0;
}

.post-job-pricing .pro-pricing {
  padding: 1.25rem;
  font-size: var(--font-size-3);
  text-align: left;
  font-weight: 400;
}

.pro-final-cost {
  color: var(--clr-brand);
  font-weight: bold;
}

.post-job-pricing input,
.post-job-form input[type='checkbox'] {
  top: 0;
}

.pro-left--bigger {
  width: 45%;
}
.pro-right--smaller {
  width: 45%;
}

@media screen and (min-width: 750px) {
  .aboutus-avatar {
    transform: perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg);
    filter: blur(0.4px);
    opacity: 0.8;
    transition: 0.6s ease all;
  }
  .aboutus-avatar:hover {
    transform: perspective(800px) rotateY(-15deg) translateY(-50px)
      rotateX(10deg) scale(1);
    filter: blur(0);
    opacity: 1;
  }
}

/* COUNTDOWN TIMER */
.count {
  --factor: var(--size);

  box-shadow: 0 10px 5px -5px rgba(0, 0, 0, 0.2);
  width: calc(225px * var(--factor));
  height: calc(275px * var(--factor));
  line-height: calc(275px * var(--factor));
  margin: 0 calc(1rem * var(--factor));
  perspective: 500px;
  position: relative;
  display: inline-block;
  text-align: center;
  transform: translateZ(0);
}
/* @media screen and (min-width: 2500px) {
  .count {
    --factor: 1;
  }
} */
@media screen and (max-width: 600px) {
  .count {
    --factor: 0.15;
  }
}
.count span {
  background: rgb(var(--rgb-tile-bottom));
  color: var(--clr-text-lightest);
  display: block;
  font-size: calc(160px * var(--factor));
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: 0 calc(150px * var(--factor)) 0;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.1) inset;
}
.count span:before {
  /* border-bottom: 1px solid #000; */
  content: '';
  left: 0;
  position: absolute;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .count span:before {
    border-bottom-width: 1px;
  }
}
.count span:after {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.count .small {
  font-size: calc(175px * var(--factor));
}
.count .top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 50%;
  overflow: hidden;
}
.count .top:before {
  bottom: 0;
}
.count .top:after {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.count .bottom {
  border-radius: 10px;
  height: 100%;
}
.count .bottom:before {
  top: 50%;
}
.count .bottom:after {
  border-radius: 10px;
}
.count.down .top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 50%;
}
.count.down .top.current {
  transform-style: flat;
  z-index: 3;
}
.count.down .top.next {
  transform: rotate3d(1, 0, 0, -90deg);
  z-index: 4;
}
.count.down .bottom {
  border-radius: 10px;
}
.count.down .bottom.current {
  z-index: 2;
}
.count.down .bottom.next {
  z-index: 1;
}
.count.down.changing .bottom.current {
  transform: rotate3d(1, 0, 0, 90deg);
  transition: transform 0.35s ease-out 0.35s;
  transform: none;
}
.count.up .top {
  height: 50%;
}
.count.up .top.current {
  z-index: 4;
}
.count.up .top.next {
  z-index: 3;
}
.count.up .bottom.current {
  z-index: 1;
}
.count.up .bottom.next {
  transform: rotate3d(1, 0, 0, 90deg);
  z-index: 2;
}
.count.up.changing .top.current {
  transform: rotate3d(1, 0, 0, -90deg);
  transition: transform 0.2625s ease-in, box-shadow 0.2625s ease-in;
}
.count.up.changing .bottom.next,
.count.up.changed .bottom.next {
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.175s cubic-bezier(0.375, 1.495, 0.61, 0.78) 0.35s,
    transform 0.35s cubic-bezier(0.375, 1.495, 0.61, 0.78) 0.35s;

  transform: rotate3d(1, 0, 0, 0);
}
.count.changed .top.current,
.count.changed .bottom.current {
  display: none;
}

.my-stats-table thead tr {
  position: sticky;
  top: 0;
}
.my-stats-table td {
  padding: 0 0.7rem;
}

.target-container {
  width: 400px;
  aspect-ratio: var(--target-aspect-ratio);
}

/** /learn **/
.cm-line {
  transform-origin: left center;
  transition: transform 0.25s ease;
}
.learn-line-highlight,
[highlight-lines*='L1L'] .cm-line:nth-child(1),
[highlight-lines*='L2L'] .cm-line:nth-child(2),
[highlight-lines*='L3L'] .cm-line:nth-child(3),
[highlight-lines*='L4L'] .cm-line:nth-child(4),
[highlight-lines*='L5L'] .cm-line:nth-child(5),
[highlight-lines*='L6L'] .cm-line:nth-child(6),
[highlight-lines*='L7L'] .cm-line:nth-child(7),
[highlight-lines*='L8L'] .cm-line:nth-child(8),
[highlight-lines*='L9L'] .cm-line:nth-child(9),
[highlight-lines*='L10L'] .cm-line:nth-child(10),
[highlight-lines*='L11L'] .cm-line:nth-child(11),
[highlight-lines*='L12L'] .cm-line:nth-child(12),
[highlight-lines*='L13L'] .cm-line:nth-child(13),
[highlight-lines*='L14L'] .cm-line:nth-child(14),
[highlight-lines*='L15L'] .cm-line:nth-child(15),
[highlight-lines*='L16L'] .cm-line:nth-child(16),
[highlight-lines*='L17L'] .cm-line:nth-child(17),
[highlight-lines*='L18L'] .cm-line:nth-child(18),
[highlight-lines*='L19L'] .cm-line:nth-child(19),
[highlight-lines*='L20L'] .cm-line:nth-child(20),
[highlight-lines*='L21L'] .cm-line:nth-child(21) {
  transform: scale(1.5) translateX(-0.4rem);
  /* transform-origin: left center; */
  /* transition: 0.25s ease; */
  /* box-shadow: 10px 1px 0px 2px var(--clr-brand), */
  /* 10px -1px 0px 2px var(--clr-brand); */
  /* box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 90%); */
}

.instruction-arrow {
  pointer-events: none;
  position: fixed;
  z-index: 10;
}
.arrow-line {
  stroke-width: 1;
  stroke: var(--clr-brand);
  fill: none;
  stroke-dasharray: 140;
  stroke-dashoffset: 140;
  transition: 1.2s ease;
  stroke-linecap: round;
  fill: transparent;
}
.arrow-line.tail {
  stroke-width: 1.5;
}
.draw .arrow-line {
  stroke-dashoffset: 0;
}
.arrow-line.tail {
  transition-delay: 0.5s;
}
.undo .arrow-line {
  stroke-dashoffset: 140;
  transition-duration: 0.5s;
}
.undo .arrow-line.tail {
  transition-delay: 0s;
}

.level-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-rows: auto 1fr auto;
  min-height: calc(100vh - 60px - 40px); /* header and footer */
}

.level-container__editor {
  grid-row: 1 / 3;
  min-height: auto;
}
.level-container__output {
  border-right: 1px solid var(--clr-border);
}
.level-container__instructions {
  padding: 0 1.25rem;
  grid-column: 2 / -1;
  transform-origin: right bottom;
}
.level-container__instructions .inner-header {
  margin-top: 0;
}
.learn-container__callout {
  background: var(--clr-box-bg);
  width: 70ch;
  box-shadow: -1px -1px 1px 0 var(--clr-border);
}
.level-container__instructions code {
  background-color: #ffdf0012;
  padding: 0.1rem;
  margin: 0 0.1rem;
  font-family: 'IBM Plex Mono';
}
.level-container--learn > div {
  transition: 0.5s ease;
}
.just-instruction .level-container__editor,
.just-instruction .level-container__output,
.just-instruction .level-container__target {
  opacity: 0;
}
.just-instruction .level-container__instructions {
  transform: scale(1.6);
}
.just-instruction .level-container__instructions .inner-header {
  opacity: 0;
}

.level-container__progress-bar {
  grid-row: 3 / -1;
  grid-column: 1 / -1;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  padding: 0.5rem 1rem;
  align-items: center;
  background: var(--clr-box-bg);
  border-top: 1px solid var(--clr-border);
}

.level-container--learn-normal {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}
.level-container--learn-normal__content {
  display: grid;
}

.modal--level-clear {
  box-shadow: 0 30px 70px 13px rgb(0 0 0 / 90%);
  border-bottom: 1px solid rgb(255 255 255 / 6%);
  margin-top: 4rem;
}
.level-container--learn .level-container__output,
.level-container--learn .level-container__target {
  /* transition: 1s ease; */
}

.level-container--learn-quiz:not([data-show-target='true'])
  .level-container__output {
  grid-column: 2 / -1;
  grid-row: 1;
}
.level-container--learn-quiz:not([data-show-target='true'])
  .level-container__output
  .item__content {
  display: flex;
  justify-content: center;
}

.level-container--learn-quiz:not([data-show-target='true'])
  .level-container__target {
  display: none;
  grid-column: 2 / -1;
  grid-row: 1;
}

.learn-mcq-modal {
  /* max-width: max-content; */
}
.learn-mcq-modal .button {
  /* This is for mcq question option buttons */
  white-space: nowrap;
}

/* changelog */
.changelog-section {
  border-radius: 0.5rem;
  background: var(--clr-box-bg);
  padding: 1rem;
  position: relative;
  font-family: ClashGrotesk;
  letter-spacing: 0.2px;
  max-width: 65ch;
  /* animation: reveal linear; */
  /* animation-timeline: view(); */
  /* animation-range: entry 0% cover 40%; */
}

.changelog-section__title {
  margin-bottom: 0.5rem;
  position: relative;
  background: var(--clr-bg);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  top: -1.5rem;
}
.changelog-section figure {
  text-align: center;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.8);
    /* scale: 0.8; */
  }
  to {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
.changelog-section:not(:last-child):after {
  --thickness: 0.5rem;
  content: '';
  display: block;
  width: 1rem;
  height: 10rem;
  border: var(--thickness) solid var(--clr-box-bg);
  border-right: 0;
  border-radius: 1rem;
  position: absolute;
  left: -1rem;
  top: calc(100% - 4rem);
}
.changelog-section:nth-child(even):not(:last-child):after {
  right: -1rem;
  left: auto;
  border: var(--thickness) solid var(--clr-box-bg);
  border-left: 0;
}

:root {
  --command-palette-animation: fadein 0.35s;
}
/* cmdk overlay */
[cmdk-overlay] {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 40%);
  backdrop-filter: blur(10px);
  inset: 0;
  z-index: 10;
  animation: var(--command-palette-animation);
}
/* cmdk dialog */
[cmdk-dialog] {
  display: flex;
  justify-content: center;
  z-index: 20;
  position: fixed;
  top: 14%;
  width: 100%;
  pointer-events: none !important;
  animation: var(--command-palette-animation);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.atropos-inner {
  overflow: initial !important;
}

/* 3 2 1 countdown */
.countdown {
  color: var(--clr-text-lightest);
}

.countdown--primary {
  text-transform: uppercase;
  font-size: calc(11rem * var(--size));
}

.countdown-wrap {
  inset: 0;
  position: fixed;
  display: grid;
  place-content: center;
  background: rgb(0 0 0 / 75%);
  backdrop-filter: blur(20px);
  z-index: 2;
}

.countdown-versus-wrap {
  position: absolute;
}

.countdown-versus-wrap::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/css-code.png');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  pointer-events: none;
}

.countdown__digit {
  position: absolute;
  top: calc(50% - 10rem);
  left: 0;
  text-align: center;
  width: 100%;
  font-size: 20rem;
  line-height: 0;
  text-shadow: 0.6rem -0.4rem 0px #7c2d2d, -0.6rem 0.4rem 0px #0dd2aa;
  color: var(--clr-brand);
  font-family: system-ui;
  font-weight: 900;
  opacity: 0;
}

.versus-countdown__digit {
  position: absolute;
  inset: 0;
}

.versus-countdown__text {
  font-size: 10rem;
  top: 40%;
}

.pro-badge:hover {
  filter: drop-shadow(0px 0px 14px yellow);
}
/* .leaderboard__user:has(.pro-badge):hover .leaderboard__user-avatar {
  filter: drop-shadow(0px 0px 25px rgb(255 255 0 / 50%));
  transform: scale(1.3);
} */
.leaderboard__user:has(.pro-badge):hover .pro-badge {
  filter: drop-shadow(0px 0px 15px rgb(255 255 0 / 80%));
}
.leaderboard__user:has(.pro-badge):hover .leaderboard__user-name {
  color: var(--clr-brand);
}

/* REDESIGN CSS */
.new-container {
  min-height: calc(100vh - 6.76rem);
  padding-left: 200px;
  background: linear-gradient(
    90deg,
    #1d232b 1.65%,
    rgba(24, 29, 35, 0.68) 200px,
    #07080b 200px
  );
}

.pill {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-0);
  padding: 0.2rem 0.5rem;
  background: var(--pill-bg);
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.pill--key {
  background: var(--pill-bg-key);
  box-shadow: 0px 2px 0px 0px var(--pill-shadow-key);
  padding: 0.04rem 0.3rem 0;
  color: var(--pill-text-key);
  font-family: 'IBM Plex Mono';
  font-weight: 600;
  line-height: 1em;
  transition: 0.2s ease;
}

.pill--key-pressed {
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.2);
  transform: translate(0px, 2px);
  color: var(--pill-text-key-hover);
}

.pill--danger {
  background: var(--pill-bg-danger);
  color: var(--pill-text-danger);
}

.pill--warning {
  background: var(--pill-bg-warning);
  color: var(--pill-text-warning);
}

.pill--success {
  background: var(--pill-bg-success);
  color: var(--pill-text-success);
}

.pill__emoji {
  font-size: 0.75rem;
  margin-right: 0.25rem;
}

.leaderboard__user {
  box-shadow: var(--hard-shadow);
  border-radius: 40px;

  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    rgba(var(--rgba-tile-bottom)) 101.76%
  );
}

.leaderboard__user--1 {
  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    var(--gold-shadow-color) 101.76%
  ) !important;
}
.leaderboard__user--2 {
  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    var(--silver-shadow-color) 101.76%
  ) !important;
}

.leaderboard__user--3 {
  background: linear-gradient(
    95.41deg,
    rgba(var(--rgba-tile-top)) 0%,
    var(--bronze-shadow-color) 101.76%
  ) !important;
}

.leaderboard__user-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* leader */
.leader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leader:has([data-rank='1']) {
  z-index: 1;
}
.leader:nth-child(2) {
  order: -1;
}
.leader [data-rank='1'] span {
  font-size: 11rem;
}

.leader__info {
  position: absolute;
  bottom: calc(100% + 0.5rem);
  width: max-content;
  transform: translateZ(1.5rem);
}

.leader__info .leader__info__1 {
  transform: translateY(20px);
}

.avatar-link__probadge-wrap {
  position: absolute;
  bottom: -4px;
  transform: translateX(-50%);
  left: 50%;
}

/** this only works when ProBadge height is 15px
*** which is currently true for all avatar images
 */
.avatar-link__image--pro {
  mask-image: linear-gradient(to top, transparent 13px, white 13px),
    linear-gradient(
      to right,
      white calc(50% - 18px),
      transparent calc(50% - 18px),
      transparent calc(50% + 18px),
      white calc(50% + 18px)
    );
  mask-size: 100% 100%;
  mask-position: bottom;
  mask-repeat: no-repeat;
  border-radius: 50%;
}

.leader__score {
  color: var(--clr-text-light);
  font-family: 'IBM Plex Mono';
}

.leader__meta {
  color: var(--clr-text-lightest-final);
}

.home-daily-target-panel {
  overflow-x: auto;
  /* scroll-behavior: smooth; */
  min-height: 415px;
  width: 100%;
}

/* .home-daily-target-panel:before,
.home-daily-target-panel:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  height: 0.5rem;
  left: 3rem;
  right: 3rem;
  background: repeating-linear-gradient(
    90deg,
    #333,
    #333 2px,
    transparent 2px 10px
  );
}
.home-daily-target-panel:after {
  height: 1.5rem;
  background: repeating-linear-gradient(
    90deg,
    #333,
    #333 2px,
    transparent 2px 5rem
  );
} */

.no-scrollbar::-webkit-scrollbar-thumb,
.no-scrollbar *::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.no-scrollbar:hover::-webkit-scrollbar-thumb,
.no-scrollbar:hover *::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

.setting-card {
  background: var(--clr-box-bg);
  border-radius: 1rem;
  padding: 1rem;
}
.setting-card__title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: var(--font-size-1);
}
.setting-card__value {
  font-weight: 700;
  font-size: var(--font-size-3);
}

.login-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  overflow: hidden;
  z-index: -1;
  text-align: center;

  background-image: url(/images/login-bottom.png);
  background-position: bottom center;
  background-repeat: repeat-x;
  background-size: contain;
  background-blend-mode: screen;
  opacity: 0.5;
}

.login-background:before,
.login-background:after {
  content: '';
  position: absolute;
  left: 0;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, var(--clr-bg), transparent);
}

.login-background:after {
  left: auto;
  right: 0;
  background: linear-gradient(to left, var(--clr-bg), transparent);
}

.daily-targets-shadowed {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    var(--tile-overlay-color),
    transparent 15%
  );
  pointer-events: none;
}

.leader-avatar {
  position: relative;
}

.leader-avatar:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(20px);
}

.leader-avatar__1:before {
  background: rgba(var(--rgb-brand), 0.5);
}
.leader-avatar__2:before {
  background: rgba(192, 192, 192, 0.5);
}
.leader-avatar__3:before {
  background: rgba(205, 127, 50, 0.5);
}

.avatar-link__image img {
  object-fit: cover;
  opacity: 0.8;
  border-radius: 50%;
}

.avatar-link__image--self > * {
  transform: scale(0.875);
}

.avatar-link__image--self {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--clr-brand);
}

.leaderboard-container {
  width: 100%;
}

.separator {
  width: 100%;
  height: 1px;
  background: var(--clr-border);
}

.separator--vertical {
  height: 100%;
  width: 1px;
  background: var(--clr-border);
}

.user-stack__image-container {
  font-size: 0;
}

.user-stack__image-container:not(:first-of-type) {
  --center: calc((-1 * var(--avatar-size) / 2) - var(--avatar-spacing));

  margin-left: var(--avatar-spacing);
  mask-image: radial-gradient(
    circle calc((var(--avatar-size) / 2) + var(--stroke-width)) at var(--center)
      50%,
    transparent 100%,
    #fff 100%
  );
}

.battle-lights-container {
  position: relative;
  pointer-events: none;
}

.battle-lights {
  position: absolute;
  top: -10.5rem;
  bottom: 0;
  width: 400px;
  opacity: 0.75;
}

.battle-lights-container--finished .battle-lights {
  opacity: 1;
}

.battle-lights--left {
  left: -500px;
  transform: scaleX(-1);
}

.battle-lights--right {
  right: -500px;
}

.battle-lights:after,
.battle-lights:before {
  --clr-1: #82ff37;
  --clr-2: rgba(255, 223, 0, 0);

  background: radial-gradient(
    64.96% 73.28% at 0% 50%,
    var(--clr-1) 0%,
    var(--clr-2) 100%
  );
}

.battle-lights-container--finished .battle-lights:after,
.battle-lights-container--finished .battle-lights:before {
  --clr-1: rgb(232, 67, 67);
  --clr-2: rgba(255, 223, 0, 0);
}

.battle-lights-container--upcoming .battle-lights:after,
.battle-lights-container--upcoming .battle-lights:before {
  --clr-1: rgb(95, 95, 255);
  --clr-2: rgba(255, 223, 0, 0);
}

.battle-lights:after,
.battle-lights:before {
  content: '';
  position: absolute;
  inset: 0;
  top: 50%;
  border-radius: 50%;
  transform-origin: left center;
  animation-name: lights-rotate;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.battle-lights--1:after {
  filter: blur(20px);
  opacity: 0.15;
  height: 140px;
  animation-delay: 0.05;
}

.battle-lights--1:before {
  filter: blur(20px);
  opacity: 0.15;
  height: 66px;
  animation-delay: 0.1;
}

.battle-lights--2:after {
  filter: blur(12px);
  opacity: 0.5;
  height: 60px;
  width: 200px;
  animation-delay: 0.15;
}

.battle-lights--2:before {
  filter: blur(4px);
  opacity: 0.5;
  height: 16px;
  width: 80px;
  animation-delay: 0.2;
}

@keyframes lights-rotate {
  0% {
    transform: translateY(-50%) rotate(145deg);
  }
  100% {
    transform: translateY(-50%) rotate(75deg);
  }
}

.input-container:has(*:focus) label {
  color: var(--clr-brand);
}

.leaderboard-stats-box {
  position: relative;
  padding: 0.75rem;
  flex-grow: 1;
  margin-top: 0.5rem;
}

.leaderboard-stats-box--large {
  padding: 1.25rem;
}

.leaderboard-stats-box:before {
  inset: 0;
  content: '';
  position: absolute;
  border-radius: 16px;
  border: 2px solid var(--clr-border);
  mask-image: radial-gradient(
    circle at center -1rem,
    transparent 0,
    transparent 1.75rem,
    white 1.75rem,
    white 100%
  );
  pointer-events: none;
}

.leaderboard-stats-box__icon {
  margin-top: -1.5rem;
  margin-bottom: 0.5rem;
}

.leaderboard-stats-box--large .leaderboard-stats-box__icon {
  margin-top: -2rem;
  margin-bottom: 1rem;
}

.skeleton {
  height: 0.5em;
  background-color: currentColor;
  border-radius: 999px;
  animation: 2s linear infinite skeleton;
  transform-origin: left;
}

@keyframes skeleton {
  20% {
    transform-origin: left;
    opacity: 1;
    transform: scaleX(1.5);
    opacity: 0.5;
  }
  20.1% {
    transform-origin: right;
    transform: scaleX(1.5) translateX(33%);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1) translateX(50%);
    opacity: 1;
  }
  70% {
    transform-origin: right;
    transform: scaleX(1.5) translateX(33%);
  }
  70.1% {
    transform-origin: left;
    transform: scaleX(1.5);
  }
  90% {
    transform-origin: left;
    transform: scaleX(1);
    opacity: 0.5;
  }

  /* 100% {
    transform-origin: right;
    transform: scaleX(1.5) translateX(33%);
    opacity: 1;
  } */
}

.profile-page-card {
  background-image: linear-gradient(
      45deg,
      rgba(var(--rgb-tile-top), 0.45),
      rgba(var(--rgb-tile-bottom), 0.45)
    ),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)),
    url(/images/pro-bg.png);
  background-position: center, center, top center;
  background-size: cover;
  border-radius: 16px;
  margin-top: 64px;
  padding: 2rem;
}

.profile-page-card .user-details__main {
  margin-top: -74px;
}
@media screen and (max-width: 600px) {
  .user-details__main {
    width: 100%;
    order: -1;
  }
  .user-details__social-links > div,
  .user-details__actions > div {
    justify-content: center !important;
  }
}

.profile-page-card .user-details__avatar {
  /* box-shadow: 0 0 0 10px var(--clr-bg); */
  background: var(--clr-bg);
}

.ticker-wrapper {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  mask-image: linear-gradient(
    90deg,
    transparent,
    white 25px,
    white calc(100% - 25px),
    transparent
  );
  max-width: 100%;
}

.ticker-element {
  display: inline-block;
  margin: 0 0.5rem;
}

.pricing-list {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.pricing-list li {
  list-style: none;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  margin: 0 -0.5rem;
}

.tv-glitch {
  max-inline-size: 100%;
  aspect-ratio: var(--target-aspect-ratio);
  border-radius: calc(var(--corner-radius, 8px) / 2);
  overflow: hidden;
  display: grid;
  place-content: center;
  position: relative;
  background: #252e36;
}
.tv-glitch svg {
  visibility: hidden;
  width: 1px;
  height: 1px;
}
.tv-glitch .tv-glitch__image {
  filter: url(#noise);
  position: absolute;
  inset: 0;
  block-size: 100%;
  opacity: var(--opacity, 0.25);
}

.bytes-target-sponsor-img {
  max-width: 80%;
  filter: grayscale(100%);
  opacity: 0.8;
  border: 1px solid var(--clr-border);
  border-radius: 0.5rem;
  padding: 1rem;
  transition: 0.3s ease;
}
.bytes-target-sponsor-img:hover {
  filter: grayscale(0%);
  opacity: 1;
}

/** ALERT */
.AlertDialogOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9998;
}

.AlertDialogContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 2rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999;
}
.AlertDialogContent:focus {
  outline: none;
}

.AlertDialogTitle {
  margin: 0;
  color: var(--clr-text);
  font-size: 1.5rem;
  font-weight: 500;
}

.AlertDialogDescription {
  margin-bottom: 20px;
  color: var(--clr-text-light);
  line-height: 1.5;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

/* submissions graph */

.calendar {
  --cell-size: 14px;
  --cell-spacing: 1px;
  --border-radius: 2px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding-left: var(--cell-size);
  margin: 0;
  max-width: 100%;
}

[data-year] {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 0;
  overflow-x: auto;
}

[data-year] .subchart__header {
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-year] > .subchart__content {
  display: flex;
  gap: var(--cell-size);
}
[data-year] .subchart__content {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 0;
}

[data-month] {
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: column;
  transition: margin 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  /* easeInOutBack */
}

[data-month] .subchart__header {
  margin: 0 0 calc(var(--cell-size) * 0.3);
  opacity: 0.66;
  text-transform: uppercase;
  font-size: 11px;
  white-space: nowrap;
}

[data-month] .subchart__content {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--cell-spacing);
  height: calc(var(--cell-size) * 7 + var(--cell-spacing) * 6);
  transition: transform 0.2s ease-in-out;
}

[data-month]:first-child .subchart__content::before {
  content: 'S M T W T F S';
  position: absolute;
  left: calc(var(--cell-size) * -1);
  line-height: calc(var(--cell-size) + var(--cell-spacing));
  width: 1px;
  font-size: calc(var(--cell-size) * 0.79);
  opacity: 0.66;
}

/*
[data-month] .content:hover {
  transform: scale( 1.25 )
}
*/
.grouped [data-month],
.seamless [data-month] {
  margin: var(--cell-size) calc(var(--cell-size) * -1) var(--cell-size) 0;
}

.seamless [data-day] {
  border-color: transparent;
}

.subchart__header {
  max-height: 36px;
  transition: max-height 0.4s ease-in-out, margin 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}

.no-labels .subchart__header {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0;
}

[data-day] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--cell-size);
  aspect-ratio: 1;
  background: #ccc;
  border-radius: var(--border-radius);
  flex-shrink: 0;
  font-size: calc(var(--cell-size) * 0.5);
  color: transparent;
  user-select: none;
  animation: fadeIn 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

[data-day].visible {
  opacity: 1;
}

[data-day='placeholder'] {
  visibility: hidden;
}

.animate__tada {
  --scaleBig: 1.25;
  --scaleSmall: 0.9;
  animation: tada 2s;
  animation-iteration-count: 2;
  animation-delay: 2s;
}
@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%,
  20% {
    transform: scale3d(var(--scaleSmall), var(--scaleSmall), var(--scaleSmall))
      rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(var(--scaleBig), var(--scaleBig), var(--scaleBig))
      rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(var(--scaleBig), var(--scaleBig), var(--scaleBig))
      rotate(-3deg);
  }

  to {
    transform: scaleX(1);
  }
}

.versus-play-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  /* height: calc((100vh - 60px) - 40px); */
}

.versus-play-container > div {
  flex: 1 0 0;
}

@import './v2.css';

input[type='date'],
input[type='time'] {
  cursor: text;
}

.fadedImageContainer {
  position: relative;
  mix-blend-mode: lighten;
}
.fadedImageContainer img {
  opacity: 0.3;
}
.fadedImageContainer::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(transparent, var(--bg-color, var(--clr-bg)) 75%);
  pointer-events: none;
}

.daily-targets-calendar-month-title {
  view-transition-name: daily-targets-calendar-month-title;
}
.daily-targets-calendar-next-button {
  view-transition-name: daily-targets-calendar-next-button;
}
.daily-targets-calendar-prev-button {
  view-transition-name: daily-targets-calendar-prev-button;
}
:root {
  view-transition-name: none;
}
.striked-out {
  position: relative;
}
.striked-out:after {
  content: '';
  position: absolute;
  inset: 45% 0 auto 0;
  height: 2px;
  background: currentColor;
  opacity: 0.7;
}

/* YEAR WRAPPED PAGE */
.year-wrapped-container {
  font-size: 2rem;
  font-family: 'ClashGrotesk';
  text-align: center;
  font-weight: 500;
}
.yw-content {
  padding: 1rem;
}
.yw-unit {
  background: var(--clr-box-bg);
  border-radius: 16px;
  padding: 2rem;
}
.yw-unit[data-bg='false'] {
  background: transparent;
  padding: 0;
}
.yw-text--big {
  font-size: 6rem;
}
.yw-text--small {
  font-size: 1.5rem;
}
.yw-highlight {
  color: var(--clr-brand);
}
.yw-number {
  /* font-family: var(--font-mono); */
  font-weight: 700;
}

.yw-target-img {
  border-radius: 8px;
  position: relative;
}
[data-rank='1'] .yw-target-img {
  height: 200px;
}
[data-rank='2'] .yw-target-img {
  height: 140px;
}
[data-rank='3'] .yw-target-img {
  height: 90px;
}
.yw-target-wrap {
  position: relative;
}
.yw-target-wrap .yw-target-img {
  margin-left: 2.3ch;
}

.yw-target__count {
  position: absolute;
  left: 0px;
  bottom: -9px;
  line-height: 1;
  font-size: 10rem;
  font-weight: 800;
  color: var(--clr-heading);
  opacity: 0.1;
  text-align: right;
}

.yw-month-chart {
  display: flex;
  gap: 4px;
  align-items: flex-end;
  height: 10ex;
}
.yw-month-chart-bar {
  width: 2.5ch;
  max-width: 5vw;
  background: var(--clr-brand);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  min-height: 1px;
}

.yw-month-chart-bar-text {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.yw-month-chart-bar:hover .yw-month-chart-bar-text {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .yw-text--big {
    font-size: 5rem;
  }
}

/* MAC OSX DOCK */
.mac-dock-container {
  position: fixed;
  bottom: 0;
  left: 13rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  z-index: calc(var(--z-index-sidebar) + 1);
}

/* Dock Base */
.mac-dock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;
  background: var(--panel-bg);
  box-shadow: var(--panel-shadow);
  backdrop-filter: blur(20px);
  border-radius: 1rem;
  padding: 1rem;
}

.mac-dock-items {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
}

/* Dock Item */
.dock-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Tooltip */
.dock-tooltip {
  --translate-x: -50%;
  position: absolute;
  bottom: 100%;
  margin-bottom: 0.8rem;
  transition: all 0.2s;
  opacity: 0;
  left: calc(var(--translate-x) * -1);
  transform: translateX(var(--translate-x)) scale(0.95);
  pointer-events: none;
  visibility: hidden;
}

.dock-item:focus-within .dock-tooltip,
.dock-item:hover .dock-tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateX(var(--translate-x)) scale(1);
  pointer-events: auto;
}

.dock-item:nth-child(-n + 6) .dock-tooltip {
  /* First 4 items align left */
  --translate-x: 0;
  left: 0;
}
.dock-item:nth-last-child(-n + 6) .dock-tooltip {
  /* Last 4 items align right */
  --translate-x: 0;
  left: auto;
  right: 0;
}

.tooltip-content {
  background: var(--clr-box-bg);
  border: 1px solid var(--clr-border);
  backdrop-filter: blur(20px);
  padding: 1rem;
  border-radius: 0.5rem;
}

.level-thumbnail {
  width: calc(calc(100vw - 50rem) / 8);
  min-width: 3rem;
  aspect-ratio: 4 / 3;
  border-radius: 0.375rem;
  overflow: hidden;
  border: 1px solid var(--clr-border);
}

.tooltip-levels {
  display: flex;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.level-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(30, 30, 30, 0.9);
  margin: 0 auto;
}

/* Dock Icon */
.dock-icon-wrapper {
  --margin-separation-hover: 1rem;
  --scale-on-hover: 2.4;
  transition: all 0.2s ease;
  transform: scale(1);
  position: relative;
  z-index: 1;
}
.dock-item:focus-within {
  z-index: 1;
}
/* Center (hovered) item */
.dock-item:focus-within .dock-icon-wrapper,
.dock-item:hover .dock-icon-wrapper {
  transform: scale(var(--scale-on-hover)) translateY(-0.5rem);
  margin-inline: var(--margin-separation-hover);
}

/* Items to the left of hovered item */
.dock-item:has(+ .dock-item:hover) .dock-icon-wrapper,
.dock-item:hover + .dock-item .dock-icon-wrapper,
.dock-item:has(+ .dock-item:focus-within) .dock-icon-wrapper,
.dock-item:focus-within + .dock-item .dock-icon-wrapper {
  transform: scale(calc(var(--scale-on-hover) * 0.8)) translateY(-0.3rem);
  margin-inline: calc(var(--margin-separation-hover) * 0.8);
}

.dock-item:has(+ .dock-item + .dock-item:hover) .dock-icon-wrapper,
.dock-item:hover + .dock-item + .dock-item .dock-icon-wrapper,
.dock-item:has(+ .dock-item + .dock-item:focus-within) .dock-icon-wrapper,
.dock-item:focus-within + .dock-item + .dock-item .dock-icon-wrapper {
  transform: scale(calc(var(--scale-on-hover) * 0.6)) translateY(-0.15rem);
  margin-inline: calc(var(--margin-separation-hover) * 0.6);
}

.dock-icon {
  width: calc(calc(100vw - 40rem) / 34);
  aspect-ratio: 1;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid var(--clr-border);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Status Indicator */
.dock-item-status {
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  width: 0.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
}
.dock-item-status[data-status='onGoing'] {
  background: var(--pill-bg-success);
}
.dock-item-status[data-status='upComing'] {
  background: var(--pill-bg-warning);
}

body:has(.mac-dock-container:focus-within) .page-content,
body:has(.mac-dock:hover) .page-content {
  transition: 0.5s ease;
  opacity: 0.7;
  filter: blur(10px);
}
