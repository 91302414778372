.page-wrapper {
  display: flex;
}

.content-wrapper {
  flex: 1;
  width: 0;
}

.page-content__inner {
  max-width: 1600px;
  margin: 0 auto;
}

.page-content__inner--fluid {
  max-width: 100%;
}

.page-content {
  padding: 3rem;
  min-height: calc(100vh - 60px - 40px); /* header and footer */
  /* overflow-y: auto; */
  position: relative;
  z-index: 0;
  overflow-x: hidden;
}

.page-content--no-padding {
  padding: 0;
}

.v2-header {
  height: 60px;
  background: linear-gradient(
    to right,
    rgb(var(--rgb-gray-0)) 50%,
    transparent
  );
  position: sticky;
  top: 0;

  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.v2-header:before {
  position: absolute;
  content: '';
  inset: 0;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: -1;
}

.header__left,
.header__right {
  width: 100%;
}

.v2-footer {
  position: relative;
  min-height: 40px;
  background: rgba(var(--rgb-gray-0), 0.75);
  border-top: 1px solid var(--clr-border-1);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: stretch;
  padding: 0.575rem 1.25rem 2rem;
  font-family: 'ClashGrotesk';
}

.v2-footer--dark {
  background: var(--clr-bg);
}

.header__live-counter {
  font-family: 'ClashGrotesk';
  font-weight: 500;
  color: var(--clr-text-lightest-final);
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.header__live-counter-number {
  color: var(--clr-text);
}

.header__live-counter-pulse {
  width: 0.6rem;
  aspect-ratio: 1;
  background: var(--clr-brand);
  margin-right: 0.25rem;
  border-radius: 100%;
  position: relative;

  box-shadow: 0 0 0 0 rgb(var(--rgb-brand));
  transform: scale(calc(1 * var(--scale, 1)));
  animation: radar 2s infinite;
}

.animation-pulse {
  animation: pulse var(--animation-pulse-duration) infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes radar {
  0% {
    transform: scale(calc(0.75 * var(--scale, 1)));
    box-shadow: 0 0 0 0 rgba(var(--rgb-brand), 0.7);
  }

  70% {
    transform: scale(calc(1 * var(--scale, 1)));
    box-shadow: 0 0 0 10px rgba(var(--rgb-brand), 0);
  }

  100% {
    transform: scale(calc(0.75 * var(--scale, 1)));
    box-shadow: 0 0 0 0 rgba(var(--rgb-brand), 0);
  }
}

.battle-info-section {
  max-width: 35rem;
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;
}

.battle-info__description {
  font-family: 'ClashGrotesk';
  font-weight: 500;
  letter-spacing: 0.02rem;
  font-size: var(--font-size-1);
  line-height: 1.6;
  color: var(--clr-text-lightest);
}

.battle-info-end-pill {
  display: inline-block;
  background: var(--clr-border-1);
  line-height: 20px;
  padding: 0.1rem 0.75rem;
  position: relative;
  z-index: 0;
  border-radius: 100px;
}

.battle-info-end-pill:before {
  content: '';
  height: 1px;
  width: 300%;
  background: var(--clr-border-1);
  position: absolute;
  top: 50%;
  z-index: -1;
  left: -100%;
}

.button.button--circular {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
}

.button.button--circular-small {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

@keyframes target-loader {
  0% {
    transform: scale(1);
    filter: grayscale(0);
  }

  10% {
    transform: scale(0.75);
    filter: grayscale(1);
  }

  40% {
    transform: scale(0.95);
    filter: grayscale(0.8);
  }
  100% {
    transform: scale(1);
    filter: grayscale(0);
  }
}
